import { CommonModule } from '@angular/common';
import {
    Component,
    OnDestroy,
    OnInit,
    Signal,
    WritableSignal,
    computed,
    effect,
    signal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NavigationEnd, Router } from '@angular/router';
import { FuseNavigationItem } from '@fuse/components/navigation';
import { TranslocoService } from '@ngneat/transloco';
import { navOptionsMsTeams } from 'app/core/navigation/navigation.data';
import { TranslateService } from 'app/core/transloco/translate.service';
import { LanguagesComponent } from 'app/layout/common/languages/languages.component';
import { OrganisationFilterComponent } from 'app/layout/common/organisation-filter/organisation-filter.component';
import { cloneDeep } from 'lodash';
import { Subject, filter } from 'rxjs';

@Component({
    selector: 'ms-teams-navigation-bar',
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
        LanguagesComponent,
        OrganisationFilterComponent,
        MatTooltipModule,
    ],
    templateUrl: './ms-teams-navigation-bar.component.html',
    styleUrl: './ms-teams-navigation-bar.component.scss',
})
export class MsTeamsNavigationBarComponent implements OnInit, OnDestroy {
    private _destory: Subject<void> = new Subject();

    private _translocoContent = toSignal(
        this._translocoService.selectTranslateObject('navigation')
    );

    navigation: WritableSignal<FuseNavigationItem[]> = signal([]);

    currentUrl: WritableSignal<string> = signal('');

    isDisabled: Signal<boolean> = computed(() => {
        return this.currentUrl().includes('error') || this.currentUrl().includes('realms');
    });

    isRealms: Signal<boolean> = computed(() => {
        return this.currentUrl().includes('realms');
    });

    showAreaInput = true;

    constructor(
        private _translocoService: TranslocoService,
        private _translateService: TranslateService,
        private router: Router
    ) {
        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe((event: NavigationEnd) => {
                this.currentUrl.set(event.url);
            });
        effect(() => {
            let content = this._translocoContent();
            let navigation = navOptionsMsTeams;

            navigation = this._translateService.replaceKeyWithContent(
                cloneDeep(navigation),
                content
            );

            this.navigation.set(navigation);
            this.currentUrl.set(this.router.url);
        });
    }
    ngOnInit(): void {
        this.currentUrl.set(this.router.url);
    }
    ngOnDestroy(): void {
        this._destory.next();
        this._destory.complete();
    }

    navigateTo(navigation: FuseNavigationItem): void {
        this.router.navigate(navigation.link.split('/'));
    }

    reload(): void {
        window.location.reload();
    }

    checkActiveComponent() {
        const currentRoute = this.router.url;
        this.showAreaInput = !currentRoute.includes('/config/md');
    }

    signOut(): void {
        localStorage.removeItem('access_token');
        this.router.navigate(['ms-teams', 'set-realms']);
    }

    trackByFn(index: number, item: any): any {
        return item.title || index;
    }
}

import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, startWith, take } from 'rxjs/operators';
import { RoleService } from './role-management.service';
import { OrganisationFilterService } from 'app/layout/common/organisation-filter/organisation-filter.service';

@Injectable({
    providedIn: 'root',
})
export class RoleGuard implements CanActivate {
    constructor(
        private orgFilter: OrganisationFilterService,
        private roleService: RoleService,
        private router: Router
    ) {}

    canActivate(route: any): Observable<boolean> | boolean {
        const requiredRoles = route.data?.roles as string[];

        if (this.roleService.rolesLoaded$.isStopped) {
            return this.checkRoles(requiredRoles);
        }

        return this.roleService.rolesLoaded$.pipe(
            filter(rolesLoaded => rolesLoaded), // Wait for roles to be loaded
            take(1), // Only take the first valid emission
            map(() => this.checkRoles(requiredRoles)) // Perform role check
        );
    }

    private checkRoles(requiredRoles: string[]): boolean {
        if (!this.roleService.hasAnyRole(requiredRoles)) {
            this.roleService.openInsufficientPermissionsDialog();
            this.router.navigate(['/forbidden']);
            return false;
        }
        return true;
    }
}

import { AppName, AvailableApp } from './apps.types';

export const availableApps: Array<AvailableApp> = [
    {
        id: AppName.PROCESSES,
        label: 'processes',
        icon: 'filter_none',
        url: 'bp',
    },
    {
        id: AppName.DATA,
        label: 'data',
        icon: 'bar_chart',
        url: 'bim',
    },
    {
        id: AppName.IMPLEMENTATION,
        label: 'implementation',
        icon: 'ballot',
        url: 'implementation',
    },

    // {
    //     id: AppName.ANALYTICS,
    //     label: 'analytics',
    //     icon: 'pie_chart',
    //     url: 'bim',
    // },
    // {
    //     id: AppName.CONFIGURATION,
    //     label: 'config',
    //     icon: 'settings',
    //     url: 'config',
    // },
    {
        id: AppName.PROJECT,
        label: 'project',
        icon: 'cloud',
        url: 'project',
    },
    {
        id: AppName.ADMINISTRATION,
        label: 'administration',
        icon: 'admin_panel_settings',
        url: 'administration',
    },
];

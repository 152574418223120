<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation
    *ngIf="displayHeaderAndNav"
    class="dark bg-gray-900 print:hidden"
    [appearance]="navigationAppearance"
    [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'"
    [navigation]="navigation.default"
    [opened]="!isScreenSmall">
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <!-- Logo -->
        <div class="flex items-center justify-center h-20">
            <div class="p-2 text-2xl bg-primary-500 font-bold text-white rounded"> EM </div>
        </div>
    </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">
    <!-- Header -->
    <div *ngIf="displayHeaderAndNav" class="header container-x--xs">
        <div class="flex items-center pr-2 space-x-2">
            <!-- Navigation toggle button -->
            <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
                <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
            </button>
            <!-- Navigation appearance toggle button -->
            <button
                class="hidden md:inline-flex"
                mat-icon-button
                (click)="toggleNavigationAppearance()">
                <mat-icon [svgIcon]="'heroicons_outline:arrows-right-left'"></mat-icon>
            </button>
            <organisation-filter *ngIf="!displayProjects && !showAreaInput"></organisation-filter>
            <project-filter *ngIf="displayProjects"></project-filter>
        </div>
        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
            <current-org></current-org>
            <button mat-icon-button (click)="openChats()">
                <mat-icon svgIcon="heroicons_outline:chat-bubble-bottom-center-text"></mat-icon>
            </button>
            <languages></languages>
            <apps></apps>
            <schemes></schemes>
            <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
            <user></user>
            <button class="lg:hidden" mat-icon-button (click)="quickChat.toggle()">
                <mat-icon [svgIcon]="'heroicons_outline:chat-bubble-left-right'"></mat-icon>
            </button>
        </div>
    </div>

    <!-- Content -->
    <div class="content flex flex-col flex-auto">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!-- Footer -->
    <div class="footer container-x--xs">
        <span class="font-medium text-secondary text-xs"
            >EM4Cloud &copy; {{ currentYear }} - v{{ version }}
        </span>
    </div>
</div>

<!-- Quick chat -->
<quick-chat #quickChat="quickChat"></quick-chat>

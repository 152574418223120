<ng-container *transloco="let t">
    <!-- Button -->
    <button mat-icon-button [matMenuTriggerFor]="userActions" id="profile-button">
        <span class="profile-initials">
            {{ user.firstname[0] + user.lastname[0] | uppercase }}
        </span>
    </button>

    <!-- Menu -->
    <mat-menu [xPosition]="'before'" #userActions="matMenu">
        <button mat-menu-item>
            <span class="flex flex-col leading-none">
                <span class="font-normal">{{ t('signedInAs') }}</span>
                <span class="mt-1.5 text-md font-medium">{{
                    user.firstname + ' ' + user.lastname
                }}</span>
            </span>
        </button>
        <mat-divider class="my-2"></mat-divider>
        <button mat-menu-item (click)="openSettings()">Settings</button>
        <mat-divider class="my-2"></mat-divider>
        <button mat-menu-item (click)="signOut()">
            <mat-icon [svgIcon]="'heroicons_outline:arrow-right-on-rectangle'"></mat-icon>
            <span>{{ t('signOut') }}</span>
        </button>
    </mat-menu>
</ng-container>

import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { RoleService } from './role-management.service';

@Injectable({
    providedIn: 'root',
})
export class RoleGuard implements CanActivate {
    constructor(
        private roleService: RoleService,
        private router: Router
    ) {}

    canActivate(route: any): boolean {
        const requiredRoles = route.data?.roles as string[];
        if (!this.roleService.hasAnyRole(requiredRoles)) {
            this.router.navigate(['/forbidden']); // Redirect to access denied page
            return false;
        }
        return true;
    }
}

import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorHandlerService } from './error-handler.service';

/**
 * Error Interceptor
 *
 * @param req
 * @param next
 */
export const errorHandlerInterceptor = (
    req: HttpRequest<unknown>,
    next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
    const errorService = inject(ErrorHandlerService);

    return next(req).pipe(
        catchError(error => {
            // Handle API error using the ErrorHandlingService
            errorService.handleApiError(error);

            // Re-throw the error to propagate it further
            return throwError(error);
        })
    );
};

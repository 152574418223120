import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { EnvironmentProviders, Provider } from '@angular/core';
import { errorHandlerInterceptor } from './error-handler.interceptor';

export const provideErrorHandler = (): Array<Provider | EnvironmentProviders> => {
    return [
        provideHttpClient(withInterceptors([errorHandlerInterceptor])),
        // {
        //     provide: ENVIRONMENT_INITIALIZER,
        //     useValue: () => inject(ErrorHandlerService),
        //     multi: true,
        // },
    ];
};

import { Component, OnInit, Signal, computed, effect, untracked } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ALL } from '../organisation-filter/organisation-filter.service';
import { ProjectFilterService } from './project-filter.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { ProjectPageService } from 'app/modules/project/project-page.service';
import { Project } from 'app/api-project';

@Component({
    selector: 'project-filter',
    templateUrl: './project-filter.component.html',
    styleUrls: ['./project-filter.component.css'],
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        MatInputModule,
        MatFormFieldModule,
        MatSelectModule,
    ],
})
export class ProjectFilterComponent implements OnInit {
    projectForm = new FormControl(ALL);
    projects: Signal<Array<Project>> = computed(this.projectFilterService.projects);

    constructor(
        private projectFilterService: ProjectFilterService,
        private _service: ProjectPageService
    ) {
        this.projectForm.setValue(this.projectFilterService.selectedProject());

        effect(
            () => {
                this.projectForm.setValue(untracked(this.projectFilterService.selectedProject));
            },
            { allowSignalWrites: true }
        );

        this.projectForm.valueChanges.pipe(takeUntilDestroyed()).subscribe(id => {
            this._service.getProjectsById(Number(id));
            this.projectFilterService.selectedProject = id;
        });
    }
    ngOnInit(): void {
        let projects = this._service.getProjects();
    }
}

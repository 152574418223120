<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Empty layout -->
<!-- ----------------------------------------------------------------------------------------------------- -->
<empty-layout *ngIf="layout === 'empty'"></empty-layout>

<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Layouts with vertical navigation -->
<!-- ----------------------------------------------------------------------------------------------------- -->

<!-- Dense -->
<dense-layout *ngIf="layout === 'dense'"></dense-layout>

<!-- Classy -->
<classy-layout *ngIf="layout === 'classy'"></classy-layout>

<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Layouts for View -->
<!-- ----------------------------------------------------------------------------------------------------- -->

<!-- View -->
<view-layout *ngIf="layout === 'view'"></view-layout>

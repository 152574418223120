import { FuseNavigationItem } from '@fuse/components/navigation';

export const navOptionsMsTeams: FuseNavigationItem[] = [
    {
        id: 'ms-view',
        title: '{{process}}',
        type: 'basic',
        icon: 'heroicons_outline:eye',
        link: '/ms-teams/view',
    },
    {
        id: 'ms-teams-calendar',
        title: '{{calendar}}',
        type: 'basic',
        icon: 'heroicons_outline:calendar-days',
        link: '/ms-teams/calendar',
    },
    {
        id: 'ms-teams-projectManagement',
        title: '{{project}}',
        type: 'basic',
        icon: 'heroicons_outline:chart-bar',
        link: '/ms-teams/project-management',
    },
    {
        id: 'ms-teams-ai-chat',
        title: '{{ai-chat}}',
        type: 'basic',
        icon: 'heroicons_outline:chat-bubble-bottom-center',
        link: '/ms-teams/chat',
    },
];

import { CommonModule } from '@angular/common';
import {
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    WritableSignal,
    computed,
    effect,
    signal,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslocoService } from '@ngneat/transloco';
import { FieldType, FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { BpmUniversalDataObject, DataManagement } from 'app/api';
import { ButtonType } from 'app/core/dialogBuilder/dialog-builder.models';
import { DialogBuilderService } from 'app/core/dialogBuilder/dialog-builder.service';
import { Subject, takeUntil } from 'rxjs';
import { SelectTableDialogComponent } from '../select-dm-object-dialog/select-dm-object-dialog.component';
import { DataManagementService } from 'app/modules/data-module/data-management/data-management.service';

@Component({
    selector: 'select-area-form-template',
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FormlyModule,
        FormlyMaterialModule,
        MatFormFieldModule,
        MatButtonModule,
    ],
    templateUrl: './select-dm-object-form-template.component.html',
    styleUrl: './select-dm-object-form-template.component.scss',
})
export class SelectDmObjectFormTemplateComponent extends FieldType implements OnInit, OnDestroy {
    private _destroy: Subject<void> = new Subject();

    private dialog: MatDialogRef<any, any>;

    protected _translocoContent = signal(null);

    private executeActionSignal: WritableSignal<string> = signal(null);

    private lastActionExecutedAt: Date | undefined = undefined;

    data: BpmUniversalDataObject;
    labelProp: string;

    dataManagementState = computed(() => this.dataManagementService.state());

    constructor(
        private dialogService: DialogBuilderService,
        private _translocoService: TranslocoService,
        private dataManagementService: DataManagementService,
        private cdr: ChangeDetectorRef
    ) {
        super();

        this._translocoService.selectTranslation('bp').subscribe(content => {
            this._translocoContent.set(content);
        });

        effect(() => {
            const code = this.executeActionSignal();
            const now = new Date();

            if (
                !this.lastActionExecutedAt ||
                now.getTime() - this.lastActionExecutedAt.getTime() > 1000
            ) {
                if (code && code.startsWith('selectArea')) {
                    const selectedDataTable = this.dataManagementService.selectedDataTable();
                    if (selectedDataTable) {
                        this.lastActionExecutedAt = now;

                        this.formControl.setValue(selectedDataTable[this.field.props.valueProp]);

                        this.form.markAsDirty();
                        this.getData();
                        this.dialog.close();
                    }
                }
            }
        });

        effect(() => {
            this.dataManagementState();
            this.getData();
        });
    }
    ngOnInit(): void {
        this.labelProp = this.field.props.labelProp;
        this.formControl.valueChanges.pipe(takeUntil(this._destroy)).subscribe(() => {
            this.getData();
        });
        this.getData();
    }
    ngOnDestroy(): void {
        this._destroy.next();
        this._destroy.complete();
    }

    findDeep(idToFind: string, objects: DataManagement[]): DataManagement | null {
        for (let index = 0; index < objects.length; index++) {
            const element = objects[index];
            if (element.id === idToFind) {
                return element;
            }
        }
        return null;
    }

    getData(): void {
        const id = this.field.formControl.value;

        const data = this.findDeep(id, this.dataManagementService.state());
        if (data) {
            this.data = {
                children: [],
                id: data.id,
                name: `${data.wbs} - ${data.titel} v${data.version}`,
            };
            this.cdr.detectChanges(); // Manually trigger change detection
        }
    }

    changeArea(): void {
        const data: DataManagement = this.dataManagementService
            .state()
            .find(d => d.id === this.formControl.value);

        this.dataManagementService.selectedDataTable = data;

        this.dialog = this.dialogService.openDialog({
            descriptor: {
                header: {
                    title: this._translocoContent()['selectAreaDialogTitle'],
                    showCloseButton: true,
                },
                content: {
                    componentConfig: {
                        component: SelectTableDialogComponent,
                        componentData: {
                            model: {},
                        },
                    },
                },
                actions: {
                    dialogActions: [
                        {
                            code: 'cancel',
                            style: ButtonType.simple,
                            title: this._translocoContent()['cancel'],
                            dismiss: true,
                        },
                        {
                            code: 'selectArea',
                            style: ButtonType.raised,
                            color: 'primary',
                            title: this._translocoContent()['selectArea'],
                            manualClose: true,
                        },
                    ],
                },
                executeActionSignal: this.executeActionSignal,
                dialogSize: 'l',
            },
        });
    }
}

<div class="p-2">
    <custom-table
        [filter]="true"
        tableName="selectCostObjectTable"
        [displayedColumns]="displayedColumns()"
        [dataSource]="dataSource()"
        [selectedRows]="selection()"
        [enableRowSelection]="true"
        [enableMultipleRowSelection]="false"
        (onRowsSelected)="onRowSelected($event)"
        [enablePagination]="true"></custom-table>
</div>

import { CommonModule, NgTemplateOutlet } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    OnDestroy,
    OnInit,
    Signal,
    ViewEncapsulation,
    computed,
    signal,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { AppName, AvailableApp } from './apps.types';
import { AppsService } from './apps.service';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoModule } from '@ngneat/transloco';
import { Router } from '@angular/router';
import { TypeService } from 'app/core/type-management/type-management.service';
import { RoleService } from 'app/core/role-management/role-management.service';

@Component({
    selector: 'apps',
    standalone: true,
    encapsulation: ViewEncapsulation.None,
    imports: [
        CommonModule,
        MatButtonModule,
        MatMenuModule,
        NgTemplateOutlet,
        MatIconModule,
        TranslocoModule,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'apps',
    templateUrl: './apps.component.html',
    styleUrls: ['./apps.component.scss'],
})
export class AppsComponent implements OnInit, OnDestroy {
    availableApps: Signal<AvailableApp[]>;
    activeApp: Signal<string> = signal('');

    constructor(
        private _appsService: AppsService,
        private _router: Router,
        private _typeService: TypeService,
        private roleService: RoleService
    ) {}

    ngOnInit(): void {
        this.activeApp = computed(() => this._appsService.activeApp?.icon);
        this.availableApps = computed(() => {
            if (this._typeService.userType() === 'SUBSCRIPTION') {
                return this._appsService.apps.filter(
                    app => app.id === AppName.PROCESSES || app.id === AppName.ADMINISTRATION
                );
            } else {
                const isAdmin = this.roleService.hasAnyRole(['ADM', 'CON']);

                if (isAdmin) {
                    return this._appsService.apps;
                }
                return this._appsService.apps.filter(
                    app => app.id === AppName.PROCESSES || app.id === AppName.ADMINISTRATION
                );
            }
        });
    }

    ngOnDestroy(): void {}

    launchApp(app: AvailableApp) {
        this._appsService.activeApp = app;
        this._router.navigateByUrl(app.url);
    }

    trackByFn(index: number, item: any): any {
        return item.id || index;
    }
}

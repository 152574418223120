import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { of } from 'rxjs';
import { AuthService } from '../../auth.service';

export const HasRoleAndPermissionGuard: CanActivateFn = (route, state) => {
    const router: Router = inject(Router);

    const auth: AuthService = inject(AuthService);

    if (!auth.hasPermissionsOnRoles(route.data.rolesAndPermissions)) {
        // Redirect to the sign-in page with a redirectUrl param
        const urlTree = router.parseUrl(`forbidden`);

        return of(urlTree);
    }
    return of(true);
};

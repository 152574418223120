import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { SilentAuthenticationService } from 'app/modules/ms-teams/silentAuthentication.service';
import { EMPTY, Observable, from, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthService } from './auth.service';

/**
 * Intercept
 *
 * @param req
 * @param next
 */

export const authInterceptor = (
    req: HttpRequest<unknown>,
    next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
    const authService = inject(AuthService);
    const router = inject(Router);
    const msAuth = inject(SilentAuthenticationService);

    const isFileUploading = req.url.indexOf('/backend/api/upload-file') > 0;
    // Clone the request and add Authorization header if access token exists
    const newReq =
        authService.accessToken && !isFileUploading
            ? req.clone({
                  headers: req.headers.set('Authorization', `Bearer ${authService.accessToken}`),
              })
            : req.clone();

    return next(newReq).pipe(
        catchError(error => {
            console.error(error);

            if (error.status === 401 || error.status === 0) {
                const teams = msAuth.teamsClient;

                if (teams) {
                    // Attempt to initialize the Teams session if available
                    // return from(msAuth.initTeamsSession()).pipe(
                    //     switchMap(() => next(newReq)), // Retry the original request
                    //     catchError(innerError => {
                    //         console.error('Teams login failed:', innerError);
                    //         return throwError(innerError);
                    //     })
                    // );
                    router.navigate(['/ms-teams', 'set-realms']);
                    return EMPTY;
                } else {
                    // Redirect to sign-out if Teams client isn't available
                    router.navigate(['/sign-out']).then(() => {
                        // Optionally handle any additional steps after navigation if needed
                        console.info('The auth token has been expired, navigated to sign out page');
                    });
                    return EMPTY; // Use EMPTY to end the observable chain
                }
            }

            return throwError(error);
        })
    );
};

export enum AppName {
    PROCESSES = 'processes',
    DATA = 'data',
    ETL = 'etl',
    ANALYTICS = 'analytics',
    PROJECT = 'project',
    ADMINISTRATION = 'administration',
    IMPLEMENTATION = 'implementation',
}

export interface AvailableApp {
    id: AppName;
    label: string;
    icon: string;
    url: string;
}

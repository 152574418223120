<div class="mb-3">
    @if (props.label) {
        <legend class="font-semibold mb-3" [class]="props.labelClass">{{
            props.label | titlecase
        }}</legend>
    }
    @if (props.description) {
        <p>{{ props.description }}</p>
    }

    @if (showError && formControl.errors) {
        <div class="alert alert-danger" role="alert">
            <formly-validation-message [field]="field"></formly-validation-message>
        </div>
    }

    <div [class]="field.fieldGroupClassName">
        @for (f of field.fieldGroup; track f) {
            <formly-field [field]="f"></formly-field>
        }
    </div>
</div>

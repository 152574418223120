import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from 'app/app.component';
import { appConfig } from 'app/app.config';
import { APP_CONFIGURATION_TOKEN } from 'app/injection-token';
import { FrontendAppConfiguration } from 'app/services/configuration.services';
import { Configuration as AIAssitantAPIConfiguration } from './app/ai-api/configuration';
import { Configuration as KeycloakAPIConfiguration } from './app/api-auth/configuration';
import { Configuration as ProjectAPIConfiguration } from './app/api-project/configuration';
import { Configuration as APIConfiguration } from './app/api/configuration';
import { Configuration as NodeJsAPIConfiguration } from './app/bff-api';
import { provideOAuthClient } from 'angular-oauth2-oidc';

// Fetch configuration from runtime-host
let host = window.location.host;

if (host === 'localhost:4200') {
    host = 'http://localhost:3000';
} else {
    host = 'https://' + host;
}

fetch(host + '/api/configuration')
    .then((response: Response) => {
        if (response.ok) {
            // Get body
            return response.json();
        } else {
            // Fail startup
            return Promise.reject(response);
        }
    })
    .then((response: FrontendAppConfiguration | Response) => {
        // Check if configuration is valid
        const config = response as FrontendAppConfiguration;

        // Always assume production mode, if it's not explicitly set to development
        if (!config.apiHost.includes('dev')) {
            enableProdMode();
        }

        const customConfiguration1 = new APIConfiguration({
            basePath: config.apiHost,
        });
        const customConfiguration2 = new ProjectAPIConfiguration({
            basePath: config.apiHost,
        });

        const aiAssistantApiConfiguration = new AIAssitantAPIConfiguration({
            basePath: config.nodeJsApi + '/api',
        });

        const keycloakApiConfiguration = new KeycloakAPIConfiguration({
            basePath: config.auth,
        });

        const nodeJsAPIConfiguration = new NodeJsAPIConfiguration({
            basePath: config.nodeJsApi + '/api',
        });

        const apiUrl = `${config.apiHost}/api`;

        bootstrapApplication(AppComponent, {
            providers: [
                {
                    provide: APP_CONFIGURATION_TOKEN,
                    useValue: config,
                },
                provideOAuthClient({
                    resourceServer: {
                        allowedUrls: [apiUrl],
                        sendAccessToken: true,
                    },
                }),
                // OpenAPI Config
                {
                    provide: APIConfiguration,
                    useValue: customConfiguration1,
                },
                {
                    provide: ProjectAPIConfiguration,
                    useValue: customConfiguration2,
                },
                {
                    provide: AIAssitantAPIConfiguration,
                    useValue: aiAssistantApiConfiguration,
                },
                {
                    provide: KeycloakAPIConfiguration,
                    useValue: keycloakApiConfiguration,
                },
                {
                    provide: NodeJsAPIConfiguration,
                    useValue: nodeJsAPIConfiguration,
                },
                ...appConfig.providers,
            ],
        }).catch(err => console.error(err));
    });

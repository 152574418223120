<!-- Button -->
<button mat-icon-button [matMenuTriggerFor]="apps">
    <ng-container *ngTemplateOutlet="appImage; context: { $implicit: activeApp() }"></ng-container>
</button>

<!-- App menu -->
<mat-menu [xPosition]="'before'" #apps="matMenu">
    <ng-container *ngFor="let app of availableApps(); trackBy: trackByFn">
        <ng-container *transloco="let t; read: 'apps'">
            <button
                mat-menu-item
                (click)="launchApp(app)"
                [class.activeItem]="activeApp() === app.icon">
                <span class="flex items-center">
                    <ng-container
                        *ngTemplateOutlet="
                            appImage;
                            context: { $implicit: app.icon }
                        "></ng-container>
                    <span class="ml-3" id="label">{{ t(app.label) }}</span>
                </span>
            </button>
        </ng-container>
    </ng-container>
</mat-menu>

<!-- App image template -->
<ng-template let-app #appImage>
    <mat-icon class="w-full">{{ app }}</mat-icon>
</ng-template>

import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, WritableSignal, effect, signal } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslocoService } from '@ngneat/transloco';
import { FieldType, FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';

import { DataManagement } from 'app/api';
import { ButtonType } from 'app/core/dialogBuilder/dialog-builder.models';
import { DialogBuilderService } from 'app/core/dialogBuilder/dialog-builder.service';
import { DataManagementService } from 'app/modules/data/data-management.service';
import { Subject } from 'rxjs';
import { SelectTransactionTableForSAPDialogComponent } from '../select-transaction-table-for-sapdialog/select-transaction-table-for-sapdialog.component';

@Component({
    selector: 'select-transaction-table-for-sap',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FormlyModule,
        FormlyMaterialModule,
        MatFormFieldModule,
        MatButtonModule,
        SelectTransactionTableForSAPDialogComponent,
    ],
    templateUrl: './select-transaction-table-for-sap.component.html',
    styleUrl: './select-transaction-table-for-sap.component.scss',
})
export class SelectTransactionTableForSAPComponent extends FieldType implements OnInit, OnDestroy {
    private _destroy: Subject<void> = new Subject();

    private dialog: MatDialogRef<any, any>;

    protected _translocoContent = signal(null);

    private executeActionSignal: WritableSignal<string> = signal(null);

    private lastActionExecutedAt: Date | undefined = undefined;

    data: WritableSignal<DataManagement> = signal(null);
    labelProp: string;

    constructor(
        private dialogService: DialogBuilderService,
        private _translocoService: TranslocoService,
        private _dataManagementService: DataManagementService
    ) {
        super();

        this._translocoService.selectTranslation('bp').subscribe(content => {
            this._translocoContent.set(content);
        });

        effect(
            () => {
                const code = this.executeActionSignal();
                const now = new Date();

                if (
                    !this.lastActionExecutedAt ||
                    now.getTime() - this.lastActionExecutedAt.getTime() > 1000
                ) {
                    if (code && code.startsWith('selectArea')) {
                        const dataTable = this._dataManagementService.selectedDataTable();

                        if (dataTable) {
                            this.data.set(dataTable);
                            this.formControl.setValue(dataTable.id);
                            this.closeAction(now);
                            this._dataManagementService.isSelectionDialog = false;
                            this.dialog.close();
                        }
                    }
                }
            },
            {
                allowSignalWrites: true,
            }
        );

        effect(
            () => {
                const state = this._dataManagementService.state();
                if (state.length) {
                    this.setInitialData();
                }
            },
            {
                allowSignalWrites: true,
            }
        );
    }

    ngOnInit(): void {
        // this.setInitialData();
    }
    ngOnDestroy(): void {
        this._destroy.next();
        this._destroy.complete();
        this._dataManagementService.isSelectionDialog = false;
    }

    setInitialData(): void {
        this.labelProp = this.field.props.labelProp;
        const formControlValue = this.formControl.getRawValue();
        if (formControlValue) {
            const data = this._dataManagementService.state().find(dm => dm.id === formControlValue);
            this.data.set(data);
        }
    }

    closeAction(now: Date): void {
        this.lastActionExecutedAt = now;
        this.executeActionSignal.set(null);
    }

    changeArea(): void {
        const selectedTable = this._dataManagementService
            .state()
            .find(dm => dm.id === this.data()?.id);
        if (selectedTable) {
            this._dataManagementService.selectedDataTable = selectedTable;
        }

        this._dataManagementService.isSelectionDialog = true;

        this.dialog = this.dialogService.openDialog({
            descriptor: {
                header: {
                    title: this._translocoContent()['selectAreaDialogTitle'],
                    showCloseButton: true,
                },
                content: {
                    componentConfig: {
                        component: SelectTransactionTableForSAPDialogComponent,
                        componentData: {
                            model: {},
                        },
                    },
                },
                actions: {
                    dialogActions: [
                        {
                            code: 'cancel',
                            style: ButtonType.simple,
                            title: this._translocoContent()['cancel'],
                            dismiss: true,
                        },
                        {
                            code: 'selectArea',
                            style: ButtonType.raised,
                            color: 'primary',
                            title: this._translocoContent()['selectArea'],
                            manualClose: true,
                        },
                    ],
                },
                executeActionSignal: this.executeActionSignal,
                dialogSize: 'fullscreen',
            },
        });
    }
}

import {
    ChangeDetectionStrategy,
    Component,
    Signal,
    WritableSignal,
    computed,
    signal,
} from '@angular/core';
import {
    ContentLayoutWrapperComponent,
    ContentLayoutWrapperConfig,
} from 'app/core/ui/content-layout-wrapper/content-layout-wrapper.component';
import { TreeConfig, TreeFlatNode, TreeNode, TreeViewComponent } from '@em4cloud/tree-view';
import { ContextMenuItemClickData } from '@em4cloud/my-cdk';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { DialogBuilderService } from 'app/core/dialogBuilder/dialog-builder.service';
import { PrettyPipe } from 'app/core/pipes/pretty.pipe';

@Component({
    selector: 'tree-test',
    standalone: true,
    imports: [
        ContentLayoutWrapperComponent,
        TreeViewComponent,
        MatIconModule,
        MatButtonModule,
        PrettyPipe,
    ],
    templateUrl: './tree-test.component.html',
    styleUrl: './tree-test.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TreeTestComponent {
    layoutConfig: Signal<ContentLayoutWrapperConfig>;
    treeConfig: WritableSignal<TreeConfig> = signal({});

    selectedNodeId: WritableSignal<string> = signal('');

    data: WritableSignal<TreeNode[]> = signal([
        {
            internalId: '1',
            id: 'xx1',
            parentId: '',
            order: 1,
            type: 1,
            attr1: 'top1',
            attr2: 'Description for top 1',
            attr3: '',
            meta: {},
            children: [
                {
                    internalId: '1.1',
                    id: 'ngbfds',
                    parentId: 'xx1',
                    order: 1,
                    type: 2,
                    attr1: 'child1.1',
                    attr2: 'Description for child1.1',
                    attr3: '',
                    meta: {},
                },
                {
                    internalId: '1.2',
                    id: 'rfedgv',
                    parentId: 'xx1',
                    order: 2,
                    type: 2,
                    attr1: 'child1.2',
                    attr2: 'Description for child1.2',
                    attr3: '',
                    meta: {},
                },
                {
                    internalId: '1.3',
                    id: 'htbred',
                    parentId: 'xx1',
                    order: 3,
                    type: 2,
                    attr1: 'child1.3',
                    attr2: 'Description for child1.3',
                    attr3: '',
                    meta: {},
                },
                {
                    internalId: '1.4',
                    id: 'grewgrd',
                    parentId: 'xx1',
                    order: 4,
                    type: 2,
                    attr1: 'child1.4',
                    attr2: 'Description for child1.4',
                    attr3: '',
                    meta: {},
                },
            ],
        },
        {
            internalId: '2',
            id: 'fgdxc',
            parentId: '',
            order: 2,
            type: 1,
            attr1: 'top2',
            attr2: 'Description for top 2',
            attr3: '',
            meta: {},
            children: [
                {
                    internalId: '2.1',
                    id: 'fds',
                    parentId: 'fgdxc',
                    order: 1,
                    type: 2,
                    attr1: 'child2.1',
                    attr2: 'Description for child2.1',
                    attr3: '',
                    meta: {},
                },
                {
                    internalId: '2.2',
                    id: 'jty',
                    parentId: 'fgdxc',
                    order: 2,
                    type: 2,
                    attr1: 'child2.2',
                    attr2: 'Description for child2.2',
                    attr3: '',
                    meta: {},
                    children: [
                        {
                            internalId: '2.2.1',
                            id: 'htrfd',
                            parentId: 'jty',
                            order: 1,
                            type: 2,
                            attr1: 'child2.2.1',
                            attr2: 'Description for child2.2.1',
                            attr3: '',
                            meta: {},
                        },
                    ],
                },
                {
                    internalId: '2.3',
                    id: 'jtf',
                    parentId: 'fgdxc',
                    order: 3,
                    type: 2,
                    attr1: 'child2.3',
                    attr2: 'Description for child2.3',
                    attr3: '',
                    meta: {},
                },
            ],
        },
    ]);

    transformFunction = (node: TreeNode, level: number): TreeFlatNode => {
        let flatNode: TreeFlatNode = {
            id: node.id,
            expandable: !!node.children,
            level: level,
            title: '',
            // title: `${node.internalId} || ${node.attr1} || ${node.attr2}`,
        };
        return flatNode;
    };

    constructor(private _dialogService: DialogBuilderService) {
        this.setTreeConfig();

        this.layoutConfig = computed(() => {
            return {
                rightSection: {
                    header: {
                        heading: '',
                    },
                },
            };
        });
    }

    setTreeConfig() {
        this.treeConfig.set({
            // internalIdPrefix: 'AA.',
            allowNodeDeselection: false,
            contextMenuConfig: {
                enableContextMenu: true,
                contextMenuItems: [
                    {
                        id: 'delete',
                        title: 'Delete',
                    },
                    {
                        id: 'copy',
                        title: 'Copy',
                    },
                    {
                        id: 'addChild',
                        title: 'Add child',
                    },
                    {
                        id: 'addSibling',
                        title: 'Add sibling',
                    },
                ],
            },
            dragDropConfig: {
                enableDragDrop: true,
                validateDrop: true,
                // validateDropLogic: '',
            },
        });
    }

    treeNodeSelectionChanged(node: TreeFlatNode) {
        if (node && node.id) {
            this.selectedNodeId.update(value => node.id);
        }
    }

    menuItemClicked($event: ContextMenuItemClickData<TreeFlatNode>) {}

    delete(id?: string) {}

    add() {}

    dataChange($event: any) {
        this.data.update(value => [...$event]);
    }
}

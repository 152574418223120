import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { ENVIRONMENT_INITIALIZER, EnvironmentProviders, Provider, inject } from '@angular/core';
import { urlInterceptor } from './url.interceptor';
import { UrlBuilderService } from './url-builder.service';

export const provideUrl = (): Array<Provider | EnvironmentProviders> => {
    return [
        provideHttpClient(withInterceptors([urlInterceptor])),
        {
            provide: ENVIRONMENT_INITIALIZER,
            useValue: () => inject(UrlBuilderService),
            multi: true,
        },
    ];
};

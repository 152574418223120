import { NgClass, TitleCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FieldArrayType, FormlyModule } from '@ngx-formly/core';

@Component({
    selector: 'formly-array-type',
    standalone: true,
    imports: [FormlyModule, TitleCasePipe, MatButtonModule, MatIconModule, NgClass],
    templateUrl: './formly-array-type.component.html',
    styleUrl: './formly-array-type.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyArrayTypeComponent extends FieldArrayType {}

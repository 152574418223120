import { Injectable, effect } from '@angular/core';
import { DataManagement, DmFile } from 'app/api';
import { DataManagementService } from 'app/modules/data-module/data-management/data-management.service';
import { BimService } from 'app/services/bim/bim.service';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FilesService {
    selection: DmFile;
    private dmFiles: DataManagement;

    constructor(
        private service: BimService,
        private _dataManagementService: DataManagementService
    ) {
        effect(() => {
            const data = this._dataManagementService.state();
            this.dmFiles = data.find(dm => dm.titel === 'dm_files');
        });
    }

    public getFiles(): Observable<DmFile[]> {
        if (this.dmFiles) {
            return this.service.getDbData(this.dmFiles, false);
        }
        return of([]);
    }
}

import { Overlay } from '@angular/cdk/overlay';
import {
    Component,
    OnDestroy,
    OnInit,
    Signal,
    WritableSignal,
    computed,
    signal,
} from '@angular/core';
import { MAT_SELECT_SCROLL_STRATEGY } from '@angular/material/select';
import { MatTableDataSource } from '@angular/material/table';
import { DmFile } from 'app/api';
import { DialogBuilderComponent } from 'app/core/dialogBuilder/dialog-builder.component';
import { Subject, takeUntil } from 'rxjs';
import {
    GridViewsModule,
    TableColumnDef,
} from '../../../../../../../../../projects/grid-views/src/public-api';
import { FilesService } from '../select-file-link-form-template/files.service';

@Component({
    selector: 'select-file-link-dialog',
    imports: [GridViewsModule],
    templateUrl: './select-file-link-dialog.component.html',
    styleUrl: './select-file-link-dialog.component.scss',
    providers: [
        {
            provide: MAT_SELECT_SCROLL_STRATEGY,
            useFactory: (overlay: Overlay) => () => overlay.scrollStrategies.reposition(),
            deps: [Overlay],
        },
    ],
})
export class SelectFileLinkDialogComponent
    extends DialogBuilderComponent<any>
    implements OnInit, OnDestroy
{
    private destroy$ = new Subject<void>();

    data: WritableSignal<any> = signal([]);
    displayedColumns: WritableSignal<Array<TableColumnDef>> = signal([]);
    dataSource: Signal<MatTableDataSource<DmFile>>;
    selection: WritableSignal<any[]> = signal([]);

    constructor(private service: FilesService) {
        super();
        this.displayedColumns.set([
            {
                columnName: 'WBS',
                columnKey: 'wbs',
                contentType: 'string',
                enableSorting: true,
            },
            {
                columnName: 'Business Domain',
                columnKey: 'businessDomain',
                contentType: 'string',
                enableSorting: true,
            },
            {
                columnName: 'Technology',
                columnKey: 'technology',
                contentType: 'string',
                enableSorting: true,
            },
            {
                columnName: 'Name',
                columnKey: 'name',
                contentType: 'string',
                enableSorting: true,
            },
            {
                columnName: 'File name',
                columnKey: 'fileName',
                contentType: 'string',
                enableSorting: true,
            },
            {
                columnName: 'Type',
                columnKey: 'type',
                contentType: 'string',
                enableSorting: true,
            },
            {
                columnName: 'Storage location',
                columnKey: 'storageLocation',
                contentType: 'string',
                enableSorting: true,
            },
            {
                columnName: 'Multiple files',
                columnKey: 'mFile',
                contentType: 'boolean',
                enableSorting: true,
            },
            {
                columnName: 'Description',
                columnKey: 'description',
                contentType: 'string',
                enableSorting: true,
            },
        ]);

        this.service
            .getFiles()
            .pipe(takeUntil(this.destroy$))
            .subscribe(res => {
                this.data.set(res);
            });

        this.dataSource = computed(
            () => new MatTableDataSource(this.data()) ?? new MatTableDataSource([])
        );
    }

    override ngOnInit(): void {
        super.ngOnInit();
        setTimeout(() => {
            this.selection.set(this.componentData.selection);
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    onRowSelected($event: any): void {
        if (!$event[0]) {
            return;
        }
        this.selection.set($event);

        this.service.selection = $event;
    }
}

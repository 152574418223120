<div
    *transloco="let t; read: 'employees'"
    class="sm:absolute sm:inset-0 flex flex-col flex-auto min-w-0 sm:overflow-hidden bg-card dark:bg-transparent">
    <!-- Header -->
    <div
        class="relative flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between py-8 px-6 md:px-8 border-b">
        <!-- Loader -->
        <div class="absolute inset-x-0 bottom-0" *ngIf="isLoading">
            <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
        </div>
        <!-- Title -->
        <div class="text-4xl font-extrabold tracking-tight">
            {{ t('employees') }}
        </div>
        <!-- Actions -->
        <div class="flex shrink-0 items-center mt-6 sm:mt-0 sm:ml-4">
            <!-- Search -->
            <mat-form-field
                class="fuse-mat-dense fuse-mat-rounded min-w-64"
                [subscriptSizing]="'dynamic'">
                <mat-icon
                    class="icon-size-5"
                    matPrefix
                    [svgIcon]="'heroicons_solid:magnifying-glass'"></mat-icon>
                <input
                    matInput
                    [formControl]="searchInputControl"
                    [autocomplete]="'off'"
                    [placeholder]="'Username or email'" />
            </mat-form-field>
            <!-- Add employee button -->
            <button class="ml-4" mat-flat-button [color]="'primary'" (click)="createEmplyoee()">
                <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
                <span class="ml-2 mr-1">
                    {{ t('add') }}
                </span>
            </button>
        </div>
    </div>

    <!-- Main -->
    <div class="flex flex-auto overflow-hidden">
        <!-- employees list -->
        <div class="flex flex-col flex-auto overflow-hidden sm:overflow-y-auto">
            <ng-container *ngIf="employees$().length > 0; else noProducts">
                <div class="grid">
                    <!-- Header -->
                    <div
                        class="inventory-grid z-10 sticky top-0 grid gap-4 py-4 px-6 md:px-8 shadow text-md font-semibold text-secondary bg-gray-50 dark:bg-black dark:bg-opacity-5"
                        matSort
                        matSortDisableClear>
                        <div></div>
                        <div [mat-sort-header]="'name'">
                            {{ t('username') }}
                        </div>
                        <div [mat-sort-header]="'firstname'">
                            {{ t('firstName') }}
                        </div>
                        <div [mat-sort-header]="'lastname'">
                            {{ t('lastName') }}
                        </div>
                        <div [mat-sort-header]="'email'">
                            {{ t('email') }}
                        </div>
                        <div></div>
                    </div>
                    <!-- Rows -->
                    <ng-container *ngFor="let employee of employees$(); trackBy: trackByFn">
                        <div
                            class="inventory-grid grid items-center gap-4 py-3 px-6 md:px-8 border-b">
                            <!-- Image -->
                            <div class="flex items-center">
                                <div
                                    class="relative flex flex-0 items-center justify-center w-12 h-12 mr-6 overflow-hidden">
                                    <img
                                        class="w-12 h-12 rounded-full"
                                        *ngIf="employee.metadata"
                                        [alt]="'employee metadata image'"
                                        [src]="employee.metadata" />
                                    <div
                                        class="flex items-center justify-center w-full h-full text-xs font-semibold leading-none text-center uppercase"
                                        *ngIf="!employee.metadata">
                                        {{ t('noThumb') }}
                                    </div>
                                </div>
                            </div>

                            <!-- Name -->
                            <div class="truncate">
                                {{ employee.user_name }}
                            </div>

                            <div>
                                {{ employee.first }}
                            </div>

                            <div>
                                {{ employee.last }}
                            </div>

                            <div>
                                {{ employee.email }}
                            </div>
                            <div>
                                <mat-icon
                                    *ngIf="employee.user_name"
                                    [matTooltip]="t('userAssigned')"
                                    svgIcon="feather:link"></mat-icon>
                                <mat-icon
                                    *ngIf="!employee.user_name"
                                    [matTooltip]="t('noUserAssigned')"
                                    class="text-orange-500"
                                    svgIcon="feather:info"></mat-icon>
                            </div>

                            <!-- Details button -->
                            <div>
                                <button
                                    class="min-w-10 min-h-7 h-7 px-2 leading-6"
                                    mat-stroked-button
                                    (click)="toggleDetails(employee.employee_id)">
                                    <mat-icon
                                        class="icon-size-5"
                                        [svgIcon]="
                                            selectedEmployee?.employee_id === employee.employee_id
                                                ? 'heroicons_solid:chevron-up'
                                                : 'heroicons_solid:chevron-down'
                                        "></mat-icon>
                                </button>
                            </div>
                        </div>
                        <div class="grid">
                            <ng-container
                                *ngIf="selectedEmployee?.employee_id === employee.employee_id">
                                <ng-container
                                    *ngTemplateOutlet="
                                        rowDetailsTemplate;
                                        context: { $implicit: employee }
                                    "></ng-container>
                            </ng-container>
                        </div>
                    </ng-container>
                </div>

                <!-- <mat-paginator
                    class="sm:absolute sm:inset-x-0 sm:bottom-0 border-b sm:border-t sm:border-b-0 z-10 bg-gray-50 dark:bg-transparent"
                    [ngClass]="{ 'pointer-events-none': isLoading }"
                    [length]="pagination.length"
                    [pageIndex]="pagination.page"
                    [pageSize]="pagination.size"
                    [pageSizeOptions]="[5, 10, 25, 100]"
                    [showFirstLastButtons]="true"></mat-paginator> -->
            </ng-container>

            <ng-template #rowDetailsTemplate let-employee>
                <div class="shadow-lg overflow-hidden">
                    <div class="flex border-b">
                        <!-- Selected employee form -->
                        <form class="flex w-full" [formGroup]="selectedEmployeeForm">
                            <div class="flex sm:flex-row p-8 gap-4 w-full">
                                <!-- employee images and status -->
                                <div class="flex flex-col items-center sm:items-start mb-8 sm:mb-0">
                                    <div class="flex flex-col p-2 items-center">
                                        <div class="w-32 rounded overflow-hidden">
                                            <ng-container
                                                *ngIf="selectedEmployee.metadata; else noImage">
                                                <img
                                                    class="w-20 h-20 mx-auto object-cover rounded-full mb-2"
                                                    src="{{ selectedEmployee.metadata }}" />
                                                <app-image-upload
                                                    (fileDropped)="
                                                        onFileDropped($event)
                                                    "></app-image-upload>
                                            </ng-container>
                                            <!-- <ng-template > -->
                                            <ng-template #noImage>
                                                <app-image-upload
                                                    (fileDropped)="
                                                        onFileDropped($event)
                                                    "></app-image-upload>
                                            </ng-template>
                                        </div>
                                    </div>
                                </div>

                                <formly-form
                                    class="w-full"
                                    [form]="selectedEmployeeForm"
                                    [fields]="fields()"
                                    [model]="selectedEmployee"></formly-form>
                                <div class="flex flex-col gap-2">
                                    <button
                                        mat-icon-button
                                        (click)="saveOrUpdateEmployee()"
                                        [matTooltip]="t('save')">
                                        <mat-icon>save</mat-icon>
                                    </button>
                                    <button
                                        *ngIf="!employee.user_name"
                                        [matTooltip]="t('remove')"
                                        mat-icon-button
                                        color="warn"
                                        (click)="remove()"
                                        ><mat-icon>delete</mat-icon></button
                                    >
                                </div>
                            </div>
                        </form>
                    </div>
                    <!-- <div class="flex items-center justify-between border-t px-8 py-4">
                        <div class="flex items-center">
                            <div class="flex items-center mr-4" *ngIf="flashMessage">
                                <ng-container *ngIf="flashMessage === 'success'">
                                    <mat-icon
                                        class="text-green-500"
                                        [svgIcon]="'heroicons_outline:check'"></mat-icon>
                                    <span class="ml-2">employee updated</span>
                                </ng-container>
                                <ng-container *ngIf="flashMessage === 'error'">
                                    <mat-icon
                                        class="text-red-500"
                                        [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
                                    <span class="ml-2">An error occurred, try again!</span>
                                </ng-container>
                            </div>
                        </div>
                    </div> -->
                </div>
            </ng-template>

            <ng-template #noProducts>
                <div class="p-8 sm:p-16 border-t text-4xl font-semibold tracking-tight text-center">
                    {{ t('noData') }}
                </div>
            </ng-template>
        </div>
    </div>
</div>

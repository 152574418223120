import { CommonModule } from '@angular/common';
import {
    Component,
    OnInit,
    Signal,
    WritableSignal,
    computed,
    effect,
    input,
    signal,
    untracked,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { NavigationEnd, Router } from '@angular/router';
import { SimpleOrganisation } from 'app/api';
import { HasTypeDirective } from 'app/core/type-management/has-type.directive';
import { UserService } from 'app/core/user/user.service';
import { User } from 'app/core/user/user.types';
import { OrgService } from 'app/modules/org-sys-area/services/org.service';
import { filter } from 'rxjs';
import {
    ALL,
    ORGANISATION_KEY_NAME,
    OrganisationFilterService,
} from './organisation-filter.service';
import { SilentAuthenticationService } from 'app/modules/ms-teams/silentAuthentication.service';

@Component({
    selector: 'organisation-filter',
    templateUrl: './organisation-filter.component.html',
    styleUrls: ['./organisation-filter.component.css'],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatInputModule,
        MatFormFieldModule,
        MatSelectModule,
        HasTypeDirective,
    ],
})
export class OrganisationFilterComponent implements OnInit {
    disabled = input(false);

    organisationForm = new FormControl(ALL);
    organisations: Signal<SimpleOrganisation[]> = computed(() => {
        let organisations = [...this._organisationFilterService.organisations()];

        let system = organisations.splice(
            organisations.findIndex(org => org.title === 'SYSTEM'),
            1
        );
        if (system.length) {
            system[0].title = this.organisationName();
        }

        organisations = organisations.sort((a, b) => (a.title > b.title ? 1 : -1));

        organisations = [...system, ...organisations];

        return organisations;
    });

    hide: WritableSignal<boolean> = signal(false);

    organisationName: WritableSignal<string> = signal('SYSTEM');

    constructor(
        private _organisationFilterService: OrganisationFilterService,
        private orgService: OrgService,
        private router: Router,
        private userService: UserService,
        private teamsAuth: SilentAuthenticationService
    ) {
        this.userService.user$.subscribe((user: User) => {
            this.organisationName.set(user.company);
        });

        // Set initial value
        this.organisationForm.setValue(this._organisationFilterService.selectedOrganisation());

        // listen to mdo data change and update form value
        effect(() => {
            let orgs = this.organisations();
            this.organisationForm.setValue(
                untracked(this._organisationFilterService.selectedOrganisation) || ALL
            );
        }, {});

        effect(() => {
            if (this.disabled()) {
                this.organisationForm.disable();
            } else {
                this.organisationForm.enable();
            }
        }, {});

        effect(() => {
            if (
                this.organisations().length &&
                ((this._organisationFilterService.selectedOrganisation() === 'null' &&
                    localStorage.getItem(ORGANISATION_KEY_NAME) === 'null') ||
                    (this._organisationFilterService.selectedOrganisation() === 'undefined' &&
                        localStorage.getItem(ORGANISATION_KEY_NAME) === 'undefined') ||
                    (!this._organisationFilterService.selectedOrganisation() &&
                        !localStorage.getItem(ORGANISATION_KEY_NAME)))
            ) {
                const newId = this.organisations()[0].organisationId ?? '';
                this.organisationForm.patchValue(newId);
            }
        });

        // listen to form data change and update global value
        this.organisationForm.valueChanges.pipe(takeUntilDestroyed()).subscribe(value => {
            this._organisationFilterService.selectedOrganisation = value;
            localStorage.setItem('organisation', value);
        });
    }

    private currentPath: string = '';

    ngOnInit(): void {
        if (!this.teamsAuth.teamsClient) {
            // this.orgService.initializeData();
        }

        // Get the initial route on load
        this.updateCurrentPath(this.router.url);

        // Subscribe to navigation events for subsequent route changes
        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe((event: NavigationEnd) => {
                this.updateCurrentPath(event.urlAfterRedirects);
            });
    }

    private updateCurrentPath(url: string): void {
        this.currentPath = url.split('/')[1]; // Extract the part after the first slash
        if (this.currentPath.includes('administration')) {
            this.hide.set(true);
        } else {
            this.hide.set(false);
        }
    }

    trackOrganisationById(index: number, organisation: any): any {
        return organisation.organisationId;
    }
}

<!-- <button ngxPrint [printSectionId]="'printSection'" [useExistingCss]="true">Print</button> -->
<button (click)="triggerPrint()">Print</button>

<div id="bikicsunáj" class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 p-4">
    <div class="full-page-content">
        <h1 class="text-3xl font-semibold mb-4">Lorem Ipsum</h1>
        <p class="text-lg leading-relaxed">{{ loremText }}</p>
    </div>

    <div class="bg-white p-6 rounded-lg shadow-lg new-page">
        <h2 class="text-xl font-semibold text-gray-800">Test</h2>
        <p class="text-gray-600 mt-2">test</p>
    </div>
    <div class="bg-white p-6 rounded-lg shadow-lg new-page">
        <h2 class="text-xl font-semibold text-gray-800">Test</h2>
        <p class="text-gray-600 mt-2">test</p>
    </div>
    <div class="bg-white p-6 rounded-lg shadow-lg new-page">
        <h2 class="text-xl font-semibold text-gray-800">Test</h2>
        <p class="text-gray-600 mt-2">test</p>
    </div>
    <div class="bg-white p-6 rounded-lg shadow-lg new-page">
        <h2 class="text-xl font-semibold text-gray-800">Test</h2>
        <p class="text-gray-600 mt-2">test</p>
    </div>
</div>

<div class="flex flex-col specialGap">
    <mat-label
        class="font-medium areaButtonLabel"
        [ngClass]="field.formControl.disabled ? 'disabled' : ''">
        {{ field.props.label }}
        {{ field.props.required ? '*' : '' }}
    </mat-label>
    <button
        [disabled]="field.formControl.disabled"
        class="areaButton bg-white dark:bg-transparent"
        mat-button
        (click)="changeArea()">
        {{
            data?.transaction?.tcode && data?.transaction?.name
                ? data.transaction.tcode + ' - ' + data.transaction.name
                : ''
        }}
    </button>
</div>

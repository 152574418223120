import { TitleCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldType, FormlyModule } from '@ngx-formly/core';

@Component({
    selector: 'formly-object-type',
    standalone: true,
    imports: [FormlyModule, TitleCasePipe],
    templateUrl: './formly-object-type.component.html',
    styleUrl: './formly-object-type.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyObjectTypeComponent extends FieldType {}

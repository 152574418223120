import { provideHttpClient } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
    PreloadAllModules,
    provideRouter,
    withInMemoryScrolling,
    withPreloading,
    withViewTransitions,
} from '@angular/router';
import { provideFuse } from '@fuse';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { appRoutes } from 'app/app.routes';
import { provideAuth } from 'app/core/auth/auth.provider';
import { provideIcons } from 'app/core/icons/icons.provider';
import { provideTransloco } from 'app/core/transloco/transloco.provider';
import { mockApiServices } from 'app/mock-api';
import { provideAngularMaterial } from './core/angular-material/angular-material.provider';
import { provideErrorHandler } from './core/error-handling/error-handler.provider';
import { provideFormly } from './core/formly/formly.provider';
import { provideUrl } from './core/urls/url.provider';

// Import required locales
import localeEn from '@angular/common/locales/en';
import localeDe from '@angular/common/locales/de';
import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import localePl from '@angular/common/locales/pl';
import localePtBR from '@angular/common/locales/pt';
import localeRu from '@angular/common/locales/ru';
import { registerLocaleData } from '@angular/common';

// Register locales
registerLocaleData(localeEn);
registerLocaleData(localeDe);
registerLocaleData(localeEs);
registerLocaleData(localeFr);
registerLocaleData(localePl);
registerLocaleData(localePtBR);
registerLocaleData(localeRu);

export const appConfig: ApplicationConfig = {
    providers: [
        provideAnimations(),
        provideHttpClient(),
        provideRouter(
            appRoutes,
            withPreloading(PreloadAllModules),
            withInMemoryScrolling({ scrollPositionRestoration: 'enabled' }),
            withViewTransitions()
        ),
        provideUrl(),
        provideErrorHandler(),

        // Angular Material
        provideAngularMaterial(),

        // Transloco Config
        provideTransloco(),

        // Calendar
        importProvidersFrom(
            CalendarModule.forRoot({
                provide: DateAdapter,
                useFactory: adapterFactory,
            })
        ),

        // Formly
        provideFormly(),

        // Fuse
        provideAuth(),
        provideIcons(),
        provideFuse({
            mockApi: {
                delay: 0,
                services: mockApiServices,
            },
            fuse: {
                layout: 'classy',
                scheme: 'light',
                screens: {
                    sm: '600px',
                    md: '960px',
                    lg: '1280px',
                    xl: '1440px',
                },
                theme: 'theme-brand',
                themes: [
                    {
                        id: 'theme-brand',
                        name: 'Brand',
                    },
                ],
            },
        }),
    ],
};

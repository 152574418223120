import { Directive, Input, TemplateRef, ViewContainerRef, effect } from '@angular/core';
import { TypeService } from './type-management.service';

@Directive({
    selector: '[appHasType]',
    standalone: true,
})
export class HasTypeDirective {
    private hasRole: string | string[];

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private roleService: TypeService
    ) {
        effect(() => {
            this.roleService.userType();
            if (this.hasRole) {
                if (Array.isArray(this.hasRole)) {
                    this.handleTypes(this.hasRole);
                } else {
                    this.handleType(this.hasRole);
                }
            }
        });
    }

    @Input() set appHasType(type: string) {
        this.handleType(type);
    }

    @Input() set appHasAtLeastOneType(types: string[]) {
        this.handleTypes(types);
    }

    private handleType(type: string) {
        // Clear the view container to avoid duplications
        this.viewContainer.clear();

        // Check if the user has the role and then create the embedded view if valid
        if (this.roleService.supportedPlans([type as any])) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        }

        this.hasRole = type;
    }

    private handleTypes(types: string[]) {
        // Clear the view container to avoid duplications
        this.viewContainer.clear();

        // Check if the user has the role and then create the embedded view if valid
        if (this.roleService.supportedPlans(types as any[])) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        }

        this.hasRole = types;
    }
}

export const environment = {
    version: '1.0.34',
    prod: false,
    originalHost: 'https://em4cloud-dev.com',
    host: 'https://em4cloud-dev.com',
    apiHost: 'https://api.em4cloud-dev.com',
    issuer: 'https://auth.em4cloud-dev.com',
    fileStorageUrlContains: 'em4cloud-bucket',
    // For url changes
    basicClientIdToBeReplaced: 'clientId',
    apiKey: 'cn2u8quNas48Lysdsin5ct',
    nodeJsApi: 'https://em4cloud-dev.com',
    nodeWSS: 'wss://em4cloud-dev.com?token=',
    auth: 'https://auth.em4cloud-dev.com',

    // Languages
    flagCodes: {
        en: 'en',
        de: 'de',
        fr: 'fr',
        es: 'es',
        pl: 'pl',
        ru: 'ru',
        'pt-BR': 'pt-BR',
    },
    subscriptionData: {
        code: 'sub',
        company: 'test.com',
    },
    postLogoutRedirectUri: 'https://registration.em4cloud-dev.com/',
};

import { Overlay } from '@angular/cdk/overlay';
import { Component } from '@angular/core';
import { MAT_SELECT_SCROLL_STRATEGY } from '@angular/material/select';
import { DialogBuilderComponent } from 'app/core/dialogBuilder/dialog-builder.component';
import { DataTreeComponent } from 'app/modules/data/data-tree/data-tree.component';

@Component({
    selector: 'select-transaction-table-for-sapdialog',
    standalone: true,
    imports: [DataTreeComponent],
    templateUrl: './select-transaction-table-for-sapdialog.component.html',
    styleUrl: './select-transaction-table-for-sapdialog.component.scss',
    providers: [
        {
            provide: MAT_SELECT_SCROLL_STRATEGY,
            useFactory: (overlay: Overlay) => () => overlay.scrollStrategies.reposition(),
            deps: [Overlay],
        },
    ],
})
export class SelectTransactionTableForSAPDialogComponent extends DialogBuilderComponent<any> {
    constructor() {
        super();
    }
}

import { Injectable } from '@angular/core';
import { NgxPrintService, PrintOptions } from 'ngx-print';
@Injectable({
    providedIn: 'root',
})
export class NgxPrintPrintSerivce {
    private printOption: PrintOptions;
    constructor(private ngxPrint: NgxPrintService) {
        this.printOption = new PrintOptions({
            bodyClass: '',
            closeWindow: false,
            openNewTab: false,
            previewOnly: false,
            printDelay: 0,
            printSectionId: 'printSection',
            printTitle: 'Printed Document',
            useExistingCss: true,
        });
    }

    public setPrintConfig(config: PrintOptions) {
        this.printOption = config;
    }

    public printReport(): void {
        this.ngxPrint.print(this.printOption);
    }
}

import { Injectable, WritableSignal, effect, signal } from '@angular/core';
import { FuseNavigationItem } from '@fuse/components/navigation';
import { TranslocoService } from '@ngneat/transloco';
import { Navigation } from 'app/core/navigation/navigation.types';
import { cloneDeep } from 'lodash';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { Observable, of } from 'rxjs';
import { AppsService } from 'app/layout/common/apps/apps.service';
import { TranslateService } from '../transloco/translate.service';
import { AppName } from 'app/layout/common/apps/apps.types';
import { RoleService } from '../role-management/role-management.service';

@Injectable({ providedIn: 'root' })
export class NavigationService {
    commonNavOptions: FuseNavigationItem[] = [];
    navOptions: Record<Partial<AppName>, FuseNavigationItem[]> = {
        // Process Navigations
        processes: [
            {
                id: 'dashboards',
                title: 'Dashboards',
                type: 'group',
                icon: 'heroicons_outline:home',
                children: [
                    {
                        id: 'dashboards.project',
                        title: '{{process}}',
                        type: 'basic',
                        icon: 'heroicons_outline:clipboard-document-check',
                        link: '/bp/dashboards/project',
                    },
                    {
                        id: 'dashboards.analytics',
                        title: '{{analytics}}',
                        type: 'basic',
                        icon: 'heroicons_outline:chart-pie',
                        link: '/bp/dashboards/analytics',
                        hidden: this.checkIfVisible.bind(this),
                    },
                ],
            },
            {
                title: '{{masterData}}',
                type: 'group',
                hidden: this.checkIfVisible.bind(this),
                children: [
                    {
                        id: 'systems2',
                        title: '{{systems}}',
                        type: 'basic',
                        icon: 'mat_solid:computer',
                        link: '/bp/master-data/systems',
                    },
                    {
                        id: 'area2',
                        title: '{{area}}',
                        type: 'basic',
                        icon: 'heroicons_outline:map',
                        link: 'bp/master-data/area',
                    },
                ],
            },
            {
                title: '{{organisations}}',
                type: 'group',
                children: [
                    {
                        id: 'organisation',
                        title: '{{organisationStructure}}',
                        type: 'basic',
                        icon: 'mat_outline:business',
                        link: '/bp/org/organisation-structure',
                    },

                    {
                        title: '{{employees}}',
                        id: 'employees',
                        type: 'basic',
                        icon: 'heroicons_outline:users',
                        link: '/bp/org/employees',
                        hidden: this.checkIfVisible.bind(this),
                    },
                    {
                        id: 'employeeAssignment',
                        title: '{{employeeAssignment}}',
                        type: 'basic',
                        icon: 'heroicons_outline:user-group',
                        link: '/bp/org/employee-assignment',
                        hidden: this.checkIfVisible.bind(this),
                    },
                ],
            },
            {
                title: '{{processes}}',
                type: 'group',
                children: [
                    {
                        id: 'management',
                        title: '{{management}}',
                        type: 'basic',
                        icon: 'heroicons_outline:arrow-path-rounded-square',
                        link: '/bp/processes',
                        queryParamsHandling: 'preserve',
                    },
                    {
                        id: 'build',
                        title: '{{build}}',
                        type: 'basic',
                        icon: 'heroicons_outline:pencil',
                        link: '/bp/task',
                        hidden: this.checkIfVisible.bind(this),
                        queryParamsHandling: 'preserve',
                    },
                    {
                        id: 'view',
                        title: '{{view}}',
                        type: 'basic',
                        icon: 'heroicons_outline:eye',
                        link: '/bp/view',
                        queryParamsHandling: 'preserve',
                    },
                ],
            },
            {
                id: 'data',
                title: 'Data',
                type: 'group',
                icon: 'heroicons_outline:home',
                children: [
                    {
                        id: 'data',
                        title: '{{edit}}',
                        type: 'basic',
                        icon: 'heroicons_outline:circle-stack',
                        link: '/bp/data/rcud',
                        queryParamsHandling: 'preserve',
                        hidden: this.checkIfVisible.bind(this),
                    },
                    {
                        id: 'data',
                        title: '{{dataView}}',
                        type: 'basic',
                        icon: 'heroicons_outline:circle-stack',
                        link: '/bp/data/view',
                        queryParamsHandling: 'preserve',
                    },
                ],
            },
        ],
        // Data Navigations
        data: [
            {
                title: '{{data}}',
                type: 'group',
                children: [
                    {
                        id: 'data-management',
                        title: '{{management}}',
                        type: 'basic',
                        icon: 'heroicons_outline:circle-stack',
                        link: '/bim/create-rcud',
                    },
                    {
                        id: 'data',
                        title: '{{data}}',
                        type: 'basic',
                        icon: 'heroicons_outline:circle-stack',
                        link: '/bim/data-rcud',
                    },
                ],
            },
        ],
        // ETL Navigations
        etl: [],
        // Analytics Navigations
        analytics: [],

        // Configuration Navigations
        configuration: [
            {
                title: '{{config}}',
                type: 'group',
                children: [
                    {
                        id: 'master-data',
                        title: '{{data}}',
                        type: 'basic',
                        icon: 'heroicons_outline:circle-stack',
                        link: '/config/md',
                    },
                    {
                        id: 'object-explorer',
                        title: '{{objectExplorer}}',
                        type: 'basic',
                        icon: 'heroicons_outline:wrench-screwdriver',
                        link: '/config/object-explorer',
                    },
                ],
            },
        ],
        project: [
            {
                id: 'project',
                title: '{{project}}',
                type: 'group',
                children: [
                    {
                        id: 'project-management',
                        title: '{{project}}',
                        type: 'basic',
                        icon: 'heroicons_outline:circle-stack',
                        link: '/project/management',
                    },
                    {
                        id: 'project',
                        title: '{{project}}',
                        type: 'basic',
                        icon: 'heroicons_outline:chart-bar',
                        link: '/project/tasks',
                    },
                ],
            },
        ],
        administration: [
            {
                id: 'administration',
                title: '{{administration}}',
                type: 'group',
                children: [
                    {
                        id: 'users',
                        title: '{{users}}',
                        type: 'basic',
                        icon: 'people',
                        link: '/administration/users',
                    },
                    {
                        id: 'organisations',
                        title: '{{organisations}}',
                        type: 'basic',
                        icon: 'mat_outline:business',
                        link: '/administration/organisations',
                    },
                    {
                        id: 'subscriptions',
                        title: '{{subscriptions}}',
                        type: 'basic',
                        icon: 'heroicons_outline:credit-card',
                        link: '/administration/subscriptions',
                    },
                ],
            },
        ],
        implementation: [
            {
                id: 'tests',
                title: '{{tests}}',
                type: 'group',
                icon: 'heroicons_outline:document-check',
                children: [
                    {
                        id: 'test-scenarios',
                        title: '{{scenarios}}',
                        type: 'basic',
                        icon: 'heroicons_outline:document-check',
                        link: '/implementation/tests/manage-scenarios',
                    },
                    {
                        id: 'test-steps',
                        title: '{{steps}}',
                        type: 'basic',
                        icon: 'heroicons_outline:document-text',
                        link: '/implementation/tests/manage-steps',
                    },
                ],
            },
            {
                type: 'divider',
            },
            {
                id: 'verify',
                title: '{{verify}}',
                type: 'basic',
                icon: 'mat_solid:verified_user',
                link: '/implementation/verify',
            },
            {
                id: 'report',
                title: '{{report}}',
                type: 'basic',
                icon: 'heroicons_outline:arrow-trending-up',
                link: '/implementation/report',
            },
            {
                id: 'calendar',
                title: '{{calendar}}',
                type: 'basic',
                icon: 'heroicons_outline:calendar-days',
                link: '/implementation/calendar',
            },
            {
                id: 'project',
                title: '{{project}}',
                type: 'group',
                children: [
                    {
                        id: 'projectManagement',
                        title: '{{projectManagement}}',
                        type: 'basic',
                        icon: 'heroicons_outline:chart-bar',
                        link: '/implementation/project-management',
                    },
                ],
            },
        ],
    };

    private _navItems: WritableSignal<Navigation> = signal(null);
    navItems$ = toObservable(this._navItems);

    private _translocoContent = toSignal(
        this._translocoService.selectTranslateObject('navigation')
    );

    /**
     * Constructor
     */
    constructor(
        private _translocoService: TranslocoService,
        private _appsService: AppsService,
        private _translateService: TranslateService,
        private _roleService: RoleService
    ) {
        effect(
            () => {
                let activeApp = this._appsService.activeApp;
                let content = this._translocoContent();

                let navigation: FuseNavigationItem[] = [
                    ...this.navOptions[activeApp.id],
                    ...this.commonNavOptions,
                ];
                navigation = this._translateService.replaceKeyWithContent(
                    cloneDeep(navigation),
                    content
                );
                let navigationTree = this.buildNavigationTree(navigation);
                this._navItems.set(navigationTree);
            },
            { allowSignalWrites: true }
        );
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for navigation
     */
    get navigation$(): Observable<Navigation> {
        return this.navItems$;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all navigation data
     */
    get(): Observable<Navigation> {
        return of(this._navItems());
    }

    buildNavigationTree(data: FuseNavigationItem[]): Navigation {
        return {
            compact: cloneDeep(data),
            default: cloneDeep(data),
            futuristic: cloneDeep(data),
            horizontal: cloneDeep(data),
        };
    }

    private checkIfVisible(item: FuseNavigationItem): boolean {
        return !this._roleService.hasAnyRole(['ADM', 'CON']);
    }
}

import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, WritableSignal, effect, signal } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslocoService } from '@ngneat/transloco';
import { FieldType, FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { AlertService } from 'app/core/alert/alert.service';
import { ButtonType } from 'app/core/dialogBuilder/dialog-builder.models';
import { DialogBuilderService } from 'app/core/dialogBuilder/dialog-builder.service';
import { DataManagementService } from 'app/modules/data/data-management.service';
import { Subject } from 'rxjs';
import { SelectTransactionDialogComponent } from '../select-transaction-dialog/select-transaction-dialog.component';
import { TransactionSelection } from '../transaction-selection.model';

@Component({
    selector: 'select-transaction',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FormlyModule,
        FormlyMaterialModule,
        MatFormFieldModule,
        MatButtonModule,
        SelectTransactionDialogComponent,
    ],
    templateUrl: './select-transaction.component.html',
    styleUrl: './select-transaction.component.scss',
})
export class SelectTransactionComponent extends FieldType implements OnInit, OnDestroy {
    private _destroy: Subject<void> = new Subject();

    private dialog: MatDialogRef<any, any>;

    protected _translocoContent = signal(null);

    private executeActionSignal: WritableSignal<string> = signal(null);

    private lastActionExecutedAt: Date | undefined = undefined;

    data: TransactionSelection;
    labelProp: string;

    constructor(
        private dialogService: DialogBuilderService,
        private _translocoService: TranslocoService,
        private _dataManagementService: DataManagementService,
        private alertService: AlertService
    ) {
        super();

        this._translocoService.selectTranslation('bp').subscribe(content => {
            this._translocoContent.set(content);
        });

        effect(
            () => {
                const code = this.executeActionSignal();
                const now = new Date();

                if (
                    !this.lastActionExecutedAt ||
                    now.getTime() - this.lastActionExecutedAt.getTime() > 1000
                ) {
                    if (code && code.startsWith('selectArea')) {
                        const transaction = this._dataManagementService.selectedRow;
                        const labelProp = this._dataManagementService.labelProp.columnKey;

                        if (transaction && labelProp) {
                            const transactionSelection = {
                                tableId: this._dataManagementService.selectedDataTable().id,
                                labelProp,
                                transaction,
                            };

                            this.formControl.setValue(JSON.stringify(transactionSelection));

                            this.data = transactionSelection;
                            this.closeAction(now);
                            this.dialog.close();
                        } else {
                            this.alertService.errorAlert(
                                'bpTask',
                                'error.missingInformationDescription',
                                'error.missingInformationTitle'
                            );
                            this.closeAction(now);
                        }
                    }
                }
            },
            {
                allowSignalWrites: true,
            }
        );
    }

    ngOnInit(): void {
        this.labelProp = this.field.props.labelProp;
        const formControlValue = this.formControl.getRawValue();

        if (formControlValue) {
            this.data = {
                labelProp: '',
                tableId: '',
                transaction: formControlValue,
            };
        }
    }
    ngOnDestroy(): void {
        this._destroy.next();
        this._destroy.complete();
        this._dataManagementService.isSelectionDialog = false;
    }

    closeAction(now: Date): void {
        this.lastActionExecutedAt = now;
        this.executeActionSignal.set(null);
    }

    findDeep(idToFind: string, objects: any[]): any | null {
        for (let index = 0; index < objects.length; index++) {
            const element = objects[index];
            if (element.id === idToFind) {
                return element;
            }

            if (element.children && element.children.length) {
                const result = this.findDeep(idToFind, element.children);
                if (result) {
                    return result;
                }
            }
        }
        return null;
    }

    changeArea(): void {
        const selectedTable = this._dataManagementService.state().find(dm => dm.category === 'S');
        selectedTable.system = this.model.system;

        if (selectedTable) {
            this._dataManagementService.selectedDataTable = selectedTable;
        }
        const transaction = this.data?.transaction;
        if (transaction) {
            this._dataManagementService.selectedRow = transaction;
        }

        this.dialog = this.dialogService.openDialog({
            descriptor: {
                header: {
                    title: this._translocoContent()['selectAreaDialogTitle'],
                    showCloseButton: true,
                },
                content: {
                    componentConfig: {
                        component: SelectTransactionDialogComponent,
                        componentData: {
                            model: {},
                        },
                    },
                },
                actions: {
                    dialogActions: [
                        {
                            code: 'cancel',
                            style: ButtonType.simple,
                            title: this._translocoContent()['cancel'],
                            dismiss: true,
                        },
                        {
                            code: 'selectArea',
                            style: ButtonType.raised,
                            color: 'primary',
                            title: this._translocoContent()['selectArea'],
                            manualClose: true,
                        },
                    ],
                },
                executeActionSignal: this.executeActionSignal,
                dialogSize: 'fullscreen',
            },
        });
    }
}

<div class="px-4 flex flex-row justify-between border-b nav-color">
    <div class="flex flex-row gap-2">
        <div class="emLogo emLogoBG"> EM </div>
        <div class="flex flex-row">
            <button
                *ngFor="let btn of navigation()"
                mat-flat-button
                class="ms-teams-button"
                [ngClass]="currentUrl === btn.link ? 'ms-teams-selected' : ''"
                (click)="navigateTo(btn)"
                [disabled]="isErrorPage">
                {{ btn.title }}
            </button>
        </div>
        <div class="my-auto">
            <organisation-filter
                *ngIf="showAreaInput"
                [disabled]="isErrorPage"></organisation-filter>
        </div>
    </div>

    <div class="flex flex-row gap-2">
        <div class="my-auto">
            <languages></languages>
        </div>
        <button class="my-auto" (click)="reload()" mat-icon-button
            ><mat-icon svgIcon="mat_solid:refresh"></mat-icon
        ></button>
    </div>
</div>

import { AsyncPipe, CurrencyPipe, NgClass, NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    Signal,
    ViewChild,
    ViewEncapsulation,
    WritableSignal,
    computed,
    effect,
    signal,
} from '@angular/core';
import {
    FormControl,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule, MatRippleModule } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { fuseAnimations } from '@fuse/animations';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { Employee, UploadFileService } from 'app/api';
import { ApiUploadFilePost200Response } from 'app/api/model/apiUploadFilePost200Response';
import { OrganisationFilterService } from 'app/layout/common/organisation-filter/organisation-filter.service';
import { UserSelectDialogComponent } from 'app/modules/maintenance-page/user-select-dialog/user-select-dialog.component';
import { Subject, takeUntil } from 'rxjs';
import { EmployeeService } from '../../employee.service';
import {
    InventoryBrand,
    InventoryCategory,
    InventoryPagination,
    InventoryTag,
    InventoryVendor,
} from '../inventory.types';
import { ImageUploadComponent } from './image-upload/image-upload.component';
import { TRANSLOCO_SCOPE, TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
    selector: 'inventory-list',
    templateUrl: './inventory.component.html',
    styles: [
        /* language=SCSS */
        `
            .inventory-grid {
                grid-template-columns: 48px auto 40px;

                @screen sm {
                    grid-template-columns: 48px 112px 112px 112px auto 48px 48px;
                }

                @screen md {
                    grid-template-columns: 48px 112px 112px 112px auto 48px 48px;
                }

                @screen lg {
                    grid-template-columns: 48px 112px 112px 112px auto 48px 48px;
                }
            }
        `,
    ],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    standalone: true,
    imports: [
        NgIf,
        MatProgressBarModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatSortModule,
        NgFor,
        NgTemplateOutlet,
        MatPaginatorModule,
        NgClass,
        MatSlideToggleModule,
        MatSelectModule,
        MatOptionModule,
        MatCheckboxModule,
        MatRippleModule,
        AsyncPipe,
        CurrencyPipe,
        ImageUploadComponent,
        ReactiveFormsModule,
        MatInputModule,
        FormlyModule,
        FormlyMaterialModule,
        TranslocoModule,
        MatTooltipModule,
    ],
    providers: [{ provide: TRANSLOCO_SCOPE, useValue: 'employees' }],
})
export class InventoryListComponent implements OnDestroy {
    @ViewChild(MatPaginator) private _paginator: MatPaginator;
    @ViewChild(MatSort) private _sort: MatSort;

    employees$: Signal<Employee[]>;
    filteredEmployees$: WritableSignal<Employee[]> = signal([]);

    brands: InventoryBrand[];
    categories: InventoryCategory[];
    filteredTags: InventoryTag[];
    flashMessage: 'success' | 'error' | null = null;
    isLoading: boolean = false;
    pagination: InventoryPagination;
    searchInputControl: FormControl;
    selectedEmployee: Employee | null = null;
    selectedEmployeeForm: FormGroup = new FormGroup({});
    tags: InventoryTag[];
    tagsEditMode: boolean = false;
    vendors: InventoryVendor[];
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    fields: WritableSignal<FormlyFieldConfig[]> = signal([]);
    filterText = signal('');

    translocoContent: WritableSignal<any> = signal({});

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseConfirmationService: FuseConfirmationService,
        private _formBuilder: UntypedFormBuilder,
        public employeeService: EmployeeService,
        private dialog: MatDialog,
        private orgService: OrganisationFilterService,
        private uploadFileService: UploadFileService,
        private translocoService: TranslocoService
    ) {
        this.translocoService
            .selectTranslation('employees')
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(content => {
                this.translocoContent.set(content);
            });

        this.searchInputControl = new FormControl();

        this.searchInputControl.valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(value => {
                this.filterText.set(value);
            });

        effect(
            () => {
                if (this.translocoContent()) this.createFormField();
            },
            {
                allowSignalWrites: true,
            }
        );

        effect(
            () => {
                if (this.orgService.selectedOrganisation()) {
                    this.employeeService.getEmployeesByOrgId(orgService.selectedOrganisation());
                }
            },
            {
                allowSignalWrites: true,
            }
        );

        this.employees$ = computed(() => {
            let employees = this.employeeService.employees().filter((emplyoee: Employee) => {
                return (
                    emplyoee.user_name.includes(this.filterText()) ||
                    emplyoee.email.includes(this.filterText())
                );
            });

            return employees;
        });
    }

    onFileDropped(fileList: FileList) {
        Array.from(fileList).forEach(file => {
            this.uploadFileService
                .backendApiUploadFilePost('thumbnail', file, 'PICTURE')
                .subscribe((data: ApiUploadFilePost200Response) => {
                    this.selectedEmployee.metadata = data.url;
                    this.employeeService.employees.update((employees: Employee[]) => {
                        return employees.map((employee: Employee) => {
                            if (employee.employee_id === this.selectedEmployee.employee_id) {
                                employee.metadata = data.url;
                            }
                            return employee;
                        });
                    });
                });
        });
    }

    openUserSelection(): void {
        const dialogRef = this.dialog.open(UserSelectDialogComponent, {
            width: '300px',
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
            }
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle product details
     *
     * @param id
     */
    async toggleDetails(id: string): Promise<void> {
        // If the product is already selected...
        if (this.selectedEmployee && this.selectedEmployee.employee_id === id) {
            // Close the details
            this.closeDetails();
            return;
        }

        const emp = await this.employeeService.getEmployee(id);

        this.selectedEmployee = emp;

        // Fill the form
        this.selectedEmployeeForm.patchValue(emp);
        this.createFormField();

        // Mark for check
        // this._changeDetectorRef.markForCheck();
    }

    private createFormField(): void {
        this.fields.set([
            {
                fieldGroupClassName: 'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 w-full',
                fieldGroup: [
                    {
                        type: 'input',
                        key: 'organisation',
                        props: {
                            label: this.translocoContent()['form.organisation'],
                            required: true,
                            disabled: true,
                        },
                    },
                    {
                        type: 'input',
                        key: 'pre',
                        props: {
                            label: this.translocoContent()['form.pre'],
                            required: false,
                        },
                    },
                    {
                        type: 'input',
                        key: 'first',
                        props: {
                            label: this.translocoContent()['form.first'],
                            required: true,
                        },
                    },
                    {
                        type: 'input',
                        key: 'last',
                        props: {
                            label: this.translocoContent()['form.last'],
                            required: true,
                        },
                    },
                    {
                        type: 'input',
                        key: 'email',
                        props: {
                            label: this.translocoContent()['form.email'],
                            required: true,
                        },
                    },
                    {
                        type: 'input',
                        key: 'phone',
                        props: {
                            label: this.translocoContent()['form.phone'],
                            required: false,
                        },
                    },
                    {
                        type: 'input',
                        key: 'department',
                        props: {
                            label: this.translocoContent()['form.department'],
                            required: false,
                        },
                    },
                    {
                        type: 'input',
                        key: 'position',
                        props: {
                            label: this.translocoContent()['form.position'],
                            required: false,
                        },
                    },
                    {
                        type: 'input',
                        key: 'notes',
                        props: {
                            label: this.translocoContent()['form.notes'],
                            required: false,
                        },
                    },
                ],
            },
        ]);
    }

    /**
     * Close the details
     */
    closeDetails(): void {
        this.selectedEmployee = null;
    }

    /**
     * Cycle through images of selected product
     */
    cycleImages(forward: boolean = true): void {
        // Get the image count and current image index
        const count = this.selectedEmployeeForm.get('images').value.length;
        const currentIndex = this.selectedEmployeeForm.get('currentImageIndex').value;

        // Calculate the next and previous index
        const nextIndex = currentIndex + 1 === count ? 0 : currentIndex + 1;
        const prevIndex = currentIndex - 1 < 0 ? count - 1 : currentIndex - 1;

        // If cycling forward...
        if (forward) {
            this.selectedEmployeeForm.get('currentImageIndex').setValue(nextIndex);
        }
        // If cycling backwards...
        else {
            this.selectedEmployeeForm.get('currentImageIndex').setValue(prevIndex);
        }
    }

    /**
     * Toggle the tags edit mode
     */
    toggleTagsEditMode(): void {
        this.tagsEditMode = !this.tagsEditMode;
    }

    /**
     * Filter tags
     *
     * @param event
     */
    filterTags(event): void {
        // Get the value
        const value = event.target.value.toLowerCase();

        // Filter the tags
        this.filteredTags = this.tags.filter(tag => tag.title.toLowerCase().includes(value));
    }

    /**
     * Filter tags input key down event
     *
     * @param event
     */
    filterTagsInputKeyDown(event): void {
        // Return if the pressed key is not 'Enter'
        if (event.key !== 'Enter') {
            return;
        }

        // If there is no tag available...
        if (this.filteredTags.length === 0) {
            // Create the tag
            this.createTag(event.target.value);

            // Clear the input
            event.target.value = '';

            // Return
            return;
        }

        // If there is a tag...
        const tag = this.filteredTags[0];
        // const isTagApplied = this.selectedEmployee.tags.find(id => id === tag.id);

        // If the found tag is already applied to the product...
        // if (isTagApplied) {
        //     // Remove the tag from the product
        //     this.removeTagFromProduct(tag);
        // } else {
        //     // Otherwise add the tag to the product
        //     this.addTagToProduct(tag);
        // }
    }

    /**
     * Create a new tag
     *
     * @param title
     */
    createTag(title: string): void {
        const tag = {
            title,
        };

        // Create tag on the server
        // this._inventoryService.createTag(tag).subscribe(response => {
        //     // Add the tag to the product
        //     this.addTagToProduct(response);
        // });
    }

    /**
     * Update the tag title
     *
     * @param tag
     * @param event
     */
    updateTagTitle(tag: InventoryTag, event): void {
        // Update the title on the tag
        tag.title = event.target.value;

        // Update the tag on the server
        // this._inventoryService.updateTag(tag.id, tag).pipe(debounceTime(300)).subscribe();

        // Mark for check
        this._changeDetectorRef.markForCheck();
    }

    /**
     * Delete the tag
     *
     * @param tag
     */
    deleteTag(tag: InventoryTag): void {
        // Delete the tag from the server
        // this._inventoryService.deleteTag(tag.id).subscribe();

        // Mark for check
        this._changeDetectorRef.markForCheck();
    }

    /**
     * Add tag to the product
     *
     * @param tag
     */
    addTagToProduct(tag: InventoryTag): void {
        // Add the tag
        // this.selectedEmployee.tags.unshift(tag.id);

        // Update the selected product form
        // this.selectedEmployeeForm.get('tags').patchValue(this.selectedEmployee.tags);

        // Mark for check
        this._changeDetectorRef.markForCheck();
    }

    /**
     * Remove tag from the product
     *
     * @param tag
     */
    removeTagFromProduct(tag: InventoryTag): void {
        // Remove the tag
        // this.selectedEmployee.tags.splice(
        //     this.selectedEmployee.tags.findIndex(item => item === tag.id),
        //     1
        // );

        // Update the selected product form
        // this.selectedEmployeeForm.get('tags').patchValue(this.selectedEmployee.tags);

        // Mark for check
        this._changeDetectorRef.markForCheck();
    }

    /**
     * Toggle product tag
     *
     * @param tag
     * @param change
     */
    toggleProductTag(tag: InventoryTag, change: MatCheckboxChange): void {
        if (change.checked) {
            this.addTagToProduct(tag);
        } else {
            this.removeTagFromProduct(tag);
        }
    }

    /**
     * Should the create tag button be visible
     *
     * @param inputValue
     */
    shouldShowCreateTagButton(inputValue: string): boolean {
        return !!!(
            inputValue === '' ||
            this.tags.findIndex(tag => tag.title.toLowerCase() === inputValue.toLowerCase()) > -1
        );
    }

    /**
     * Create product
     */
    createEmplyoee(): void {
        let employeesWithNew = [
            ...this.employeeService.employees(),
            {
                organisation: this.orgService.selectedOrganisation(),
                pre: '',
                first: '',
                last: '',
                abbr: '',
                metadata: '',
                roles: [],
                lang: '',
                address: '',
                email: '',
                phone: '',
                department: '',
                position: '',
                notes: '',
                infotypes: '',
                employee_id: '',
                user_name: '',
            },
        ];
        this.employeeService.employees.set(employeesWithNew);
    }

    /**
     * Update the selected product using the form data
     */
    updateSelectedProduct(): void {
        // Get the product object
        const product = this.selectedEmployeeForm.getRawValue();

        // Remove the currentImageIndex field
        delete product.currentImageIndex;

        // Update the product on the server
        // this._inventoryService.updateProduct(product.id, product).subscribe(() => {
        //     // Show a success message
        //     this.showFlashMessage('success');
        // });
    }

    /**
     * Delete the selected product using the form data
     */
    deleteSelectedProduct(): void {
        // Open the confirmation dialog
        const confirmation = this._fuseConfirmationService.open({
            title: 'Delete product',
            message: 'Are you sure you want to remove this product? This action cannot be undone!',
            actions: {
                confirm: {
                    label: 'Delete',
                },
            },
        });

        // Subscribe to the confirmation dialog closed action
        confirmation.afterClosed().subscribe(result => {
            // If the confirm button pressed...
            if (result === 'confirmed') {
                // Get the product object
                const product = this.selectedEmployeeForm.getRawValue();

                // Delete the product on the server
                // this._inventoryService.deleteProduct(product.id).subscribe(() => {
                //     // Close the details
                //     this.closeDetails();
                // });
            }
        });
    }

    /**
     * Show flash message
     */
    showFlashMessage(type: 'success' | 'error'): void {
        // Show the message
        this.flashMessage = type;

        // Mark for check
        this._changeDetectorRef.markForCheck();

        // Hide it after 3 seconds
        setTimeout(() => {
            this.flashMessage = null;

            // Mark for check
            this._changeDetectorRef.markForCheck();
        }, 3000);
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    saveOrUpdateEmployee(): void {
        if (this.selectedEmployee.employee_id) {
            this.employeeService.updateEmployee(this.selectedEmployee);
        } else {
            this.employeeService.createEmployee(this.selectedEmployee);
        }
    }

    remove(): void {
        let employeesPoped = [...this.employeeService.employees()];
        employeesPoped.pop();

        this.employeeService.employees.set(employeesPoped);
    }
}

import { Injectable, WritableSignal, signal } from '@angular/core';
import { AvailableApp } from './apps.types';
import { availableApps } from './apps.data';

@Injectable({ providedIn: 'root' })
export class AppsService {
    private _apps: WritableSignal<Array<AvailableApp>>;
    private _activeApp: WritableSignal<AvailableApp>;

    constructor() {
        this._setApps();
    }

    private _setApps() {
        this._apps = signal(availableApps);
        this._activeApp = signal(availableApps[0]);
        for (let i = 0; i < this._apps().length; i++) {
            if (window.location.pathname.slice(1).startsWith(this._apps()[i].url)) {
                this._activeApp.set(this._apps()[i]);
                break;
            }
        }
    }

    get apps() {
        return this._apps();
    }

    get activeApp() {
        return this._activeApp();
    }

    set activeApp(app: AvailableApp) {
        this._activeApp.set(app);
    }
}

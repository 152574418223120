<div>
    <h2 mat-dialog-title>Select a User</h2>

    <mat-dialog-content>
        <mat-selection-list [(ngModel)]="selectedUser">
            <mat-list-option *ngFor="let user of settingService.users()" [value]="user">
                {{ user.keycloakUsername }}
            </mat-list-option>
        </mat-selection-list>
    </mat-dialog-content>

    <div class="w-full flex flex-row justify-between">
        <button mat-button (click)="onCancel()">Cancel</button>
        <button mat-button [mat-dialog-close]="selectedUser" (click)="select()">Select</button>
    </div>
</div>

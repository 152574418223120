<div class="flex flex-col specialGap">
    <mat-label
        class="font-medium areaButtonLabel"
        [ngClass]="field.formControl.disabled ? 'disabled' : ''">
        {{ field.props.label }}
        {{ field.props.required ? '*' : '' }}
    </mat-label>
    <button
        [disabled]="field.formControl.disabled"
        class="areaButton"
        mat-button
        (click)="changeArea()">
        {{ data() ? data().titel : '' }}
    </button>
</div>

<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">
    <!-- Content -->
    <div class="content flex flex-col flex-auto">
        <ms-teams-navigation-bar></ms-teams-navigation-bar>
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <div class="ms-content flex flex-auto h-full">
            <router-outlet *ngIf="true"></router-outlet>
        </div>
    </div>
</div>

import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, WritableSignal, signal } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslocoService } from '@ngneat/transloco';
import { FieldType, FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { CostObject } from 'app/api';
import { ButtonType } from 'app/core/dialogBuilder/dialog-builder.models';
import { DialogBuilderService } from 'app/core/dialogBuilder/dialog-builder.service';
import { Subject } from 'rxjs';
import { SelectServiceDialogComponent } from '../select-service-dialog/select-service-dialog.component';
import { ServiceEntryService } from './service-entry.service';

@Component({
    selector: 'select-service-form-template',
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FormlyModule,
        FormlyMaterialModule,
        MatFormFieldModule,
        MatButtonModule,
    ],
    templateUrl: './select-service-form-template.component.html',
    styleUrl: './select-service-form-template.component.scss',
})
export class SelectServiceFormTemplateComponent extends FieldType implements OnInit, OnDestroy {
    private _destroy: Subject<void> = new Subject();

    private dialog: MatDialogRef<any, any>;

    protected _translocoContent = signal(null);

    private executeActionSignal: WritableSignal<string> = signal(null);

    data: CostObject;
    labelProp: string;

    constructor(
        private dialogService: DialogBuilderService,
        private _translocoService: TranslocoService,
        private service: ServiceEntryService
    ) {
        super();

        this._translocoService.selectTranslation('bp').subscribe(content => {
            this._translocoContent.set(content);
        });
    }

    ngOnInit(): void {
        this.setInitialData();
    }
    ngOnDestroy(): void {
        this._destroy.next();
        this._destroy.complete();
    }

    setInitialData(): void {
        this.labelProp = this.field.props.labelProp;
        const formControlValue = this.formControl.getRawValue();

        if (formControlValue) {
            this.data = formControlValue;
        }
    }

    closeAction(now: Date): void {
        this.executeActionSignal.set(null);
    }

    selectFile(): void {
        this.service.selection = this.data;

        this.dialog = this.dialogService.openDialog({
            descriptor: {
                header: {
                    title: this._translocoContent()['selectAreaDialogTitle'],
                    showCloseButton: true,
                },
                content: {
                    componentConfig: {
                        component: SelectServiceDialogComponent,
                        componentData: {
                            model: {},
                            selection: this.data ? [this.data] : [],
                            executeActionSignal: this.executeActionSignal,
                        },
                    },
                },
                actions: {
                    dialogActions: [
                        {
                            code: 'cancel',
                            style: ButtonType.simple,
                            title: this._translocoContent()['cancel'],
                            dismiss: true,
                        },
                        {
                            code: 'selectAccount',
                            style: ButtonType.raised,
                            color: 'primary',
                            title: this._translocoContent()['selectAccount'],
                        },
                    ],
                },
                executeActionSignal: this.executeActionSignal,
                dialogSize: 'fullscreen',
            },
        });

        this.dialog.afterClosed().subscribe(() => {
            const selection = this.service.selection[0];
            this.formControl.setValue(selection);
            this.data = selection;
            this.form.markAsDirty();
        });
    }
}

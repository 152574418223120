import { Injectable, Signal, computed, effect, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Translation, TranslocoService } from '@ngneat/transloco';
import { Project } from 'app/api-project';

import { ProjectPageService } from 'app/modules/project/project-page.service';
import { cloneDeep } from 'lodash';

export const PROJECT_KEY_NAME = 'Project';

@Injectable({
    providedIn: 'root',
})
export class ProjectFilterService {
    private _translocoContent: Signal<Translation>;
    private _selectedProject = signal<string>(null);

    projects: Signal<Array<Project>> = computed(() => {
        let projects: Array<Project> = [];
        if (this._service.projects) {
            projects = cloneDeep(this._service.projects);

            // if (!projects.some(area => area.id === ALL)) {
            //     projects.push({ id: ALL, name: this._translocoContent()['buttons.selectAll'] });
            // }
        }
        return projects;
    });

    constructor(
        private _service: ProjectPageService,
        private _translocoService: TranslocoService
    ) {
        this._translocoContent = toSignal(this._translocoService.selectTranslation());

        const project = localStorage.getItem(PROJECT_KEY_NAME);
        this._selectedProject.set(project);

        // synchronize local storage
        effect(() => {
            localStorage.setItem(PROJECT_KEY_NAME, this._selectedProject());
        });
    }

    public get selectedProject(): Signal<string> {
        return this._selectedProject.asReadonly();
    }

    public set selectedProject(area: string) {
        this._selectedProject.set(area);
    }
}

import { inject } from '@angular/core';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { forkJoin } from 'rxjs';
import { MDOService } from './modules/master-data/master-data/mdo.service';

export const initialDataResolver = () => {
    const navigationService = inject(NavigationService);
    const mdoService = inject(MDOService);

    // Fork join multiple API endpoint calls to wait all of them to finish
    return forkJoin([navigationService.get(), mdoService.getAll()]);
};

<div class="mb-3">
    <div class="flex gap-2 items-center mb-3">
        @if (props.label) {
            <legend class="font-semibold" [class]="props.labelClass">{{
                props.label | titlecase
            }}</legend>
        }
        @if (!options?.formState?.disabled) {
            <button mat-icon-button color="primary" (click)="add()">
                <mat-icon>add_circle</mat-icon>
            </button>
        }
    </div>

    @if (props.description) {
        <p>{{ props.description }}</p>
    }

    @if (showError && formControl.errors) {
        <div class="alert alert-danger" role="alert">
            <formly-validation-message [field]="field"></formly-validation-message>
        </div>
    }

    @for (field of field.fieldGroup; track field; let index = $index, last = $last) {
        <div class="flex gap-2" [ngClass]="{ 'border-b-2 mb-3': !last }">
            <formly-field [field]="field" class="flex-1"></formly-field>
            @if (!options?.formState?.disabled) {
                <button mat-icon-button color="warn" (click)="remove(index)" class="mt-6">
                    <mat-icon>remove_circle</mat-icon>
                </button>
            }
        </div>
    }
</div>

import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';
import { UrlBuilderService } from './url-builder.service';

import { OAuthService } from 'angular-oauth2-oidc';
import { ServerSentEventService } from 'app/services/sse/server-sent-event.service';
import { TranslocoService } from '@ngneat/transloco';
import { APP_CONFIGURATION_TOKEN } from 'app/injection-token';

/**
 * Intercept
 *
 * @param req
 * @param next
 */
export const urlInterceptor = (
    req: HttpRequest<unknown>,
    next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
    const urlBuilderService = inject(UrlBuilderService);
    const oauth2 = inject(OAuthService);
    const sseService = inject(ServerSentEventService);
    const _translocoService = inject(TranslocoService);
    const appConfig = inject(APP_CONFIGURATION_TOKEN);

    if (!appConfig) {
        return next(req);
    }

    if (
        // req.url.includes(urlBuilderService.basePathInGeneratedClient) ||
        req.url.includes(appConfig.host) ||
        req.url.includes(appConfig.apiHost) ||
        req.url.includes(appConfig.originalHost) ||
        req.url.includes(appConfig.nodeJsApi) ||
        req.url.includes('https://auth.em4cloud-dev.com')
    ) {
        const expiration = oauth2.getAccessTokenExpiration();
        const now = new Date();

        let givenHost;
        if (req.url.includes('protocol/openid-connect/token')) {
            givenHost = appConfig.issuer;
        }
        if (expiration) {
            if (expiration > now.getTime()) {
                // console.info('expiration > now.getTime() true');
            } else {
                // console.info('expiration > now.getTime() false');
                sessionStorage.clear();
            }
        } else {
            //sessionStorage.clear();
        }

        let token = sessionStorage.getItem('access_token');
        if (!token) {
            token = localStorage.getItem('access_token');
        }

        // return next(modifiedReq);
        let headers = req.headers;

        if (req.url.indexOf('/backend/api/upload-file') > 0) {
            headers = headers.delete('Authorization');

            let modifiedReq = req.clone({
                // url: urlBuilderService.buildUrlForInterceptor(req.url, givenHost),
                url: req.url,
                headers: headers,
            });

            return next(modifiedReq);
        }

        if (token) {
            headers = headers.set('Authorization', 'Bearer ' + token);
        }

        if (req.url.includes(appConfig.apiHost)) {
            headers = headers
                .set('x-sse-id', sseService.getSseId().toString())
                .set('x-user-lang', _translocoService.getActiveLang())
                .set('x-api-key', appConfig.apiKey)
                .set('x-org-id', localStorage.getItem('organisation'));
        }

        let modifiedReq = req.clone({
            // url: urlBuilderService.buildUrlForInterceptor(req.url, givenHost),
            url: req.url,
            headers: headers,
        });
        return next(modifiedReq);
    } else {
        return next(req);
    }
};

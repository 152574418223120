<div class="absolute inset-0 flex flex-col min-w-0 overflow-hidden [&>*]:flex-1">
    <content-layout-wrapper [config]="layoutConfig()">
        <div leftContentHeader class="flex justify-between items-center gap-4">
            <!-- <div>
                <button mat-icon-button (click)="delete()">
                    <mat-icon>delete</mat-icon>
                </button>
                <button mat-icon-button (click)="add()">
                    <mat-icon>add</mat-icon>
                </button>
            </div> -->
        </div>
        <div leftContent>
            <custom-tree
                class="w-full h-full"
                [data]="data"
                [treeConfig]="treeConfig()"
                [transformFunction]="transformFunction"
                (treeNodeSelectionChange)="treeNodeSelectionChanged($event)"
                (menuItemClick)="menuItemClicked($event)"
                (dataChange)="dataChange($event)">
            </custom-tree>
        </div>
        <div rightContent [innerHTML]="data() | pretty"></div>
    </content-layout-wrapper>
</div>

import { inject } from '@angular/core';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { forkJoin } from 'rxjs';
import { OrgService } from './modules/org-sys-area/services/org.service';

export const initialDataResolver = () => {
    const navigationService = inject(NavigationService);
    const orgService = inject(OrgService);

    // Fork join multiple API endpoint calls to wait all of them to finish
    return forkJoin([navigationService.get(), orgService.getSimpleOrganisationsForUsers()]);
};

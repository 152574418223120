import { EnvironmentProviders, Provider, importProvidersFrom } from '@angular/core';
import { FormlyModule } from '@ngx-formly/core';
import { SelectMdObjectFormTemplateComponent } from 'app/modules/bp-management/custom-form-templates/select-md-object-form-template/select-md-object-form-template.component';
import { SelectTransactionTableForSAPComponent } from 'app/modules/object-explorer/objects-editor/components/select-transaction-table-for-sap/select-transaction-table-for-sap.component';
import { SelectDmObjectFormTemplateComponent } from 'app/modules/task/right-contents/maintain-form-components/maintain-interface-components/custom-form-templates/select-dm-object-form-template/select-dm-object-form-template.component';
import { SelectFileLinkFormTemplateComponent } from 'app/modules/task/right-contents/maintain-form-components/maintain-interface-components/custom-form-templates/select-file-link-form-template/select-file-link-form-template.component';
import { SelectJiraAccountsFormTemplateComponent } from 'app/modules/task/right-contents/maintain-form-components/maintain-interface-components/custom-form-templates/select-jira-accounts-form-template/select-jira-accounts-form-template.component';
import { SelectTransactionComponent } from 'app/modules/task/right-contents/maintain-form-components/maintain-tasks/sap-task-form/custom-form-template/select-transaction/select-transaction.component';
import { FormlyArrayTypeComponent } from './form-types/formly-array-type/formly-array-type.component';
import { FormlyObjectTypeComponent } from './form-types/formly-object-type/formly-object-type.component';
import { SelectCostObjectFormTemplateComponent } from 'app/modules/task/right-contents/maintain-form-components/maintain-interface-components/custom-form-templates/select-cost-object-form-template/select-cost-object-form-template.component';
import { SelectServiceFormTemplateComponent } from 'app/modules/task/right-contents/maintain-form-components/maintain-interface-components/custom-form-templates/select-service-form-template/select-service-form-template.component';
import { noCapitalLetters } from 'app/modules/data-module/data-management/components/data-fields/data-fields.service';

export const provideFormly = (): Array<Provider | EnvironmentProviders> => {
    return [
        importProvidersFrom(
            FormlyModule.forRoot({
                validationMessages: [
                    { name: 'required', message: 'This field is required' },
                    { name: 'noCapitalLetters', message: 'No special characters' },
                ],
                types: [
                    {
                        name: 'md-object-dialog',
                        component: SelectMdObjectFormTemplateComponent,
                    },
                    {
                        name: 'dm-object-dialog',
                        component: SelectDmObjectFormTemplateComponent,
                    },
                    {
                        name: 'object',
                        component: FormlyObjectTypeComponent,
                    },
                    {
                        name: 'array',
                        component: FormlyArrayTypeComponent,
                    },
                    {
                        name: 'select-transaction',
                        component: SelectTransactionComponent,
                    },
                    {
                        name: 'select-transaction-table',
                        component: SelectTransactionTableForSAPComponent,
                    },
                    {
                        name: 'select-jira-accounts',
                        component: SelectJiraAccountsFormTemplateComponent,
                    },
                    {
                        name: 'select-file-links',
                        component: SelectFileLinkFormTemplateComponent,
                    },
                    {
                        name: 'select-cost-object',
                        component: SelectCostObjectFormTemplateComponent,
                    },
                    {
                        name: 'select-service',
                        component: SelectServiceFormTemplateComponent,
                    },
                ],
                validators: [{ name: 'noCapitalLetters', validation: noCapitalLetters }],
            })
        ),
    ];
};

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NgxPrintModule } from 'ngx-print';
import { NgxPrintPrintSerivce } from './ngx-print.service';

@Component({
    selector: 'print-test',
    imports: [NgxPrintModule],
    templateUrl: './print-test.component.html',
    styleUrl: './print-test.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [NgxPrintPrintSerivce],
})
export class PrintTestComponent {
    constructor(private print: NgxPrintPrintSerivce) {
        this.print.setPrintConfig({
            bodyClass: '',
            closeWindow: false,
            openNewTab: false,
            previewOnly: false,
            printDelay: 0,
            printSectionId: 'bikicsunáj',
            printTitle: 'Printed Document',
            useExistingCss: true,
        });
    }

    loremText: string = `
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla ac ante a leo posuere condimentum non nec magna. Vivamus tincidunt felis justo, vel vehicula metus facilisis ut. Sed eget vestibulum nulla. Sed ac gravida odio. Nunc efficitur, erat id laoreet sodales, arcu felis facilisis turpis, sit amet dictum lorem mi at nulla. Quisque malesuada ut metus eget sollicitudin. Aenean in neque felis. Mauris aliquet nunc et cursus consectetur. Etiam eleifend ante at lectus dictum, sit amet tempus odio tristique. Aenean at nisl vel ligula vulputate cursus. 
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum eget purus eros. Donec vel libero feugiat, vestibulum ligula ac, sodales libero. Ut eget posuere ante. Nunc euismod libero et ante placerat, et porttitor lorem tristique. Duis sit amet orci vel orci egestas dignissim. Integer vehicula hendrerit erat. Sed hendrerit libero ut erat interdum, a tincidunt purus venenatis.
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum eget purus eros. Donec vel libero feugiat, vestibulum ligula ac, sodales libero. Ut eget posuere ante. Nunc euismod libero et ante placerat, et porttitor lorem tristique. Duis sit amet orci vel orci egestas dignissim. Integer vehicula hendrerit erat. Sed hendrerit libero ut erat interdum, a tincidunt purus venenatis.
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla ac ante a leo posuere condimentum non nec magna. Vivamus tincidunt felis justo, vel vehicula metus facilisis ut. Sed eget vestibulum nulla. Sed ac gravida odio. Nunc efficitur, erat id laoreet sodales, arcu felis facilisis turpis, sit amet dictum lorem mi at nulla. Quisque malesuada ut metus eget sollicitudin. Aenean in neque felis. Mauris aliquet nunc et cursus consectetur. Etiam eleifend ante at lectus dictum, sit amet tempus odio tristique. Aenean at nisl vel ligula vulputate cursus. 
  `;

    triggerPrint(): void {
        this.print.printReport();
    }
}

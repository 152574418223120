<div [ngClass]="hide() ? 'invisible' : ''">
    <mat-form-field *appHasType="'CORPORATE'" appearance="fill" class="my-auto w-64">
        <mat-select [formControl]="organisationForm">
            @for (organisation of organisations(); track organisation.organisationId) {
                <mat-option [value]="organisation.organisationId">
                    {{ organisation.title }}
                </mat-option>
            }
        </mat-select>
    </mat-form-field>
</div>

import { Component, OnInit } from '@angular/core';
import { MatProgressSpinnerModule, MatSpinner } from '@angular/material/progress-spinner';

@Component({
    selector: 'loading-spinner',
    templateUrl: './loading-spinner.component.html',
    styleUrls: ['./loading-spinner.component.css'],
    standalone: true,
    imports: [MatProgressSpinnerModule],
})
export class LoadingSpinnerComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}

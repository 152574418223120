import { Component } from '@angular/core';
import { BpmUniversalDataObject } from 'app/api';
import { DialogBuilderComponent } from 'app/core/dialogBuilder/dialog-builder.component';
import { DataTreeComponent } from 'app/modules/data/data-tree/data-tree.component';

@Component({
    selector: 'select-area-dialog',
    standalone: true,
    imports: [DataTreeComponent],
    templateUrl: './select-dm-object-dialog.component.html',
    styleUrl: './select-dm-object-dialog.component.scss',
})
export class SelectTableDialogComponent extends DialogBuilderComponent<BpmUniversalDataObject> {
    constructor() {
        super();
    }
}

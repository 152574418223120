import { ChangeDetectionStrategy, Component, CUSTOM_ELEMENTS_SCHEMA, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { UserDto } from 'app/api';
import { MatListModule } from '@angular/material/list';
import { CommonModule, NgIf } from '@angular/common';
import { SettingSerivce } from 'app/modules/settings/setting.service';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
@Component({
    selector: 'user-select-dialog',
    standalone: true,
    imports: [NgIf, MatButtonModule, MatListModule, FormsModule, MatDialogModule, CommonModule],
    templateUrl: './user-select-dialog.component.html',
    styleUrl: './user-select-dialog.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class UserSelectDialogComponent {
    users: UserDto[] = [];
    selectedUser: UserDto | null = null;

    constructor(
        public dialogRef: MatDialogRef<UserSelectDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public settingService: SettingSerivce
    ) {
        this.users = this.settingService.users();
    }

    onCancel(): void {
        this.dialogRef.close();
    }

    select(): void {
        console.log(this.selectedUser);
    }
}
